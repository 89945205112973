@import 'variables';

.v-woordzoeker {
    display: grid;
    grid-row-gap: 1px;
    grid-column-gap: 1px;
    background-color: #bbbbbb;
    width: fit-content;
    border: 1px solid #bbbbbb;

    &__container {
        overflow-x: auto;
    }

    &__cel {
        position: relative;
        width: 40px;
        height: 40px;
        background-color: $white;
        text-align: center;

        &:hover {
            cursor: pointer;
        }

        &.v-woordzoeker__cel--selected {
            background-color: #eeeeee;
        }

        &.v-woordzoeker__cel--current {
            background-color: #cccccc;
        }

        &__letter {
            color: $black;
            position: relative;
            font-size: 20px;
            line-height: 40px;
            width: 40px;
            height: 40px;
            text-transform: uppercase;
        }

        &__debug {
            position: absolute;
            right: 0;
            bottom: 0;
            font-size: 10px;
            line-height: 10px;
            color: #bbbbbb;
            display: none;
        }
    }
}

.v-woordzoeker-woorden {
    &.toon-vraag {
        .v-woordzoeker-woorden-vraag {
            display: block;
        }

        .v-woordzoeker-woorden-woord {
            color: #ffffff;
            border: 1px solid #ced4da;

            &.focus {
                border-color: $black;
            }
        }
    }

    .v-woordzoeker-woorden-vraag {
        display: none;
        line-height: 30px;
        width: 300px;
        color: $black;

        &.focus {
            font-weight: 500;
        }
    }

    .v-woordzoeker-woorden-woord {
        color: $black;
        width: 200px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        line-height: 30px;
        height: 30px;

        &.focus {
            font-weight: 500;
        }
    }
}

.woordzoeker-woorden {
    border-top: 1px solid #bbbbbb;

    .woordzoeker-woord {
        border-bottom: 1px solid #bbbbbb;
    }

    .woordzoeker-woord > div:last-child {
        display: none;
    }

    &.toon-vraag {
        .woordzoeker-woord > div:last-child {
            display: block;
        }
    }
}
