.bk-icon {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background-color: transparent;
    transition: background-color 200ms ease;
    cursor: pointer;
    font-size: 16px;
    line-height: 16px;
    user-select: none;

    &--small {
        width: 24px;
        height: 24px;
    }

    &:hover {
        background-color: #f1f1f1;
    }

    &--no-hover {
        &:hover {
            background-color: transparent;
        }
    }

    &--remove {
        color: #e94f35;
    }

    &--add {
        color: #009ede;
    }

    &--grab {
        cursor: grab;
    }

    &--outline {
        border: 1px solid #ced4da;
        border-radius: 0.25rem;
        i {
            font-size: 10px;
        }
    }

    &__svg {

        &:after {
            content: "";
            position: absolute;
            width: 20px;
            height: 20px;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            background-image: url("/svg/sample.svg");
            background-size: cover;
        }
    }

    &__rectangle-small {
        border: 1px solid #cccccc;
        border-radius: 2px;
        width: 40px;
        height: 20px;
        line-height: 1;
    }

    &__rectangle-medium {
        transform: scale(2, 1);
    }

    &__rectangle-large {
        transform: scale(2.5, 1);
    }

    &__rectangle-multiple {
        transform: scale(2.5, 2);
    }
}

.panel__middle .bk-icon {
    border: 1px solid #009ede;
    height: 32px;
    background-color: #fff;
}