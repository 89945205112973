@import 'variables';

.gekoppeld-items-container {
    margin-top: 16px;

    .gekoppeld-item {
        margin-bottom: 12px;

        .form-group {
            margin-bottom: 0;
            position: relative;

            &::after {
                font-family: "Font Awesome 6 Pro";
                position: absolute;
                top: 2px;
                right: 3px;
                width: 20px;
                height: 20px;
                text-align: center;
            }

            &--text {
                &::after {
                    content: "\f15c";
                }
            }

            &--afbeelding {
                &::after {
                    content: "\f03e";
                }
            }

            &--video {
                &::after {
                    content: "\f4e1";
                }
            }

            &--audio {
                &::after {
                    content: "\f58f";
                }
            }

            input {
                padding-right: 28px;
            }
        }
    }
}
