@import 'variables';

.v-stelling {
    &__content {
        //border: 1px solid #ced4da;
        border-radius: 0.2rem;
        margin-top: -6px;
        line-height: 30px;
        min-height: 30px;
        flex: 1;

        &:focus-visible {
            //outline: -webkit-focus-ring-color auto 1px;
        }
    }

    &__vraag {
        width:100%;
        display:flex;
        margin-top:5px;

        &__vraagstelling {
            margin-right:10px;
        }

        input {
            flex: 2;
        }
    }

}

.v-stelling__vraag.hide {
    display: none !important;
}

.stelling {
    display: inline-block;
    border: 1px solid #ccc;
    padding: 0px 10px;
    border-radius: 4px;
    min-width:20px;
    cursor:pointer;

    &.right {
        float:right;
    }

    &.focus {
        border: 1px solid #009ede;
    }

    &__veld {
        &__container {
            margin: -10px -20px;
        }

        &__items {
            border-bottom: 1px solid #eeeeee;
            padding: 12px 20px;

            &:last-child {
                border-bottom-width: 0px;
            }
        }
    }
}

.stelling-veld-item {
    margin-bottom:8px;
}