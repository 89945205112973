@import 'variables';

.multiselect-native-select {
    .btn-group {
        width: 100%;

        .multiselect-container {
            border-color: #e2e8f0 ;
        }

        .multiselect {
            text-align: left !important;
        }
    }

    .multiselect-container {
        width: 100%;
    }
}

