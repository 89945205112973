.treejs {
    &-label {
        //white-space: nowrap;
        //overflow: hidden;
        //text-overflow: ellipsis;
    }
    &-node {
        padding-bottom: 4px;
    }
}
