body .dataTables_wrapper {

    .dataTables_custom_top {
        display: flex;
        justify-content: space-between;
        margin-bottom: 4px;
    }

    .dataTables_filter {
        input {
            margin: 0;
        }
    }

    .dataTable {
        //border-width: 0 !important;
        margin: 0;

        thead,
        tbody {
            tr {
                border-width: 0 !important;

                th,
                td {
                    border-width: 0 0 1px 0 !important;
                    border-bottom-color: #e2e8f0 !important;
                    border-bottom-style: solid !important;

                    line-height: 32px;
                    font-size: 14px;
                    font-weight: 400;
                    padding: 2px 0 !important;

                    &.actions {
                        text-align: right;
                        min-width: 300px;
                    }

                    &.reorder {
                        cursor: move;
                    }

                    .btn {
                        margin-left: 12px;
                    }
                }

                th {
                    font-weight: 700;
                    text-transform: uppercase;
                }


                }
        }
    }

    .dataTables_custom_bottom {
        display: flex;
        justify-content: space-between;
        line-height: 38px;
        font-size: 14px;
        margin: 15px 0 0;

        .dataTables_info,
        .dataTables_paginate {
            padding: 0;
            margin: 0;
        }

        .dataTables_info {
            margin: 12px 0 0;
            width: 200px;
            text-align: right;
            line-height: 16px;
            font-size: 12px;
            font-weight: 400;
            color: #2a2a29;
        }

        .dataTables_custom {
            width: 200px;
        }

        .dataTables_paginate {
            padding: 0;
            margin: 0;
        }
    }
}

.bk-dt-btn {
    border: 1px solid #dfdfdf;
    padding: 0 4px;
    line-height: 16px;
    font-size: 12px;
    font-weight: 400;
    color: #2a2a29;
    margin: 0 4px;
    cursor: pointer;

    &.active {
        background-color: #dfdfdf;
    }

    &.previous,
    &.next {
        border-style: none;
        text-transform: lowercase;
    }

    &:hover {
        color: #009ede;
    }
}
