@import 'variables';

.v-woord {
    &__veld {
        line-height: 20px;
        width: 150px;
        background-color: #ffffff;
        border: 1px solid #ced4da;
        border-radius: .25rem;
        transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
        outline: 0;

        &.focus {
            border: 1px solid $black;
        }

        &[data-type="1"] {
            width: 100px;
        }

        &[data-type="2"] {
            width: 175px;
        }

        &[data-type="3"] {
            width: 250px;
        }

        &[data-type="4"] {
            width: 100%;
            min-height: 46px;
        }
    }
}

.woord {
    &__veld {
        border-bottom: 1px solid #eeeeee;
        padding: 12px 20px;

        &:last-child {
            border-bottom-width: 0px;
        }

        .woord-veld-aantal_antwoordregels {
            display: none;
        }

        &[data-type="4"] {
            .woord-veld-aantal_antwoordregels {
                display: block;
            }
        }

        &__container {
            margin: -10px -20px;
        }
    }
}
