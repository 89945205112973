.truncate {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

[contenteditable] {
    outline: 0px solid transparent;
}

.cke_notification_warning {
    display:none;
}