@import 'variables';

.vraag[data-soort="meerkeuze"] {
    .v-meerkeuze-items {

        &.show-checkbox .v-meerkeuze-item__checkbox {
            display: block;
        }

        &.show-checkbox .v-meerkeuze-item__abc {
            display: none;
        }

        .v-meerkeuze-item {
            line-height: 30px;
            height: 30px;

            &:nth-child(1) .v-meerkeuze-item__abc::before {
                content: 'a.';
            }

            &:nth-child(2) .v-meerkeuze-item__abc::before {
                content: 'b.';
            }

            &:nth-child(3) .v-meerkeuze-item__abc::before {
                content: 'c.';
            }

            &:nth-child(4) .v-meerkeuze-item__abc::before {
                content: 'd.';
            }

            &:nth-child(5) .v-meerkeuze-item__abc::before {
                content: 'e.';
            }

            &:nth-child(6) .v-meerkeuze-item__abc::before {
                content: 'f.';
            }

            &__checkbox {
                display: none;
                border: 1px solid #ced4da;
                width: 14px;
                height: 14px;
                margin: 8px 12px 3px 0;
                border-radius: 3px;
            }

            &__abc {
                position: relative;
                width: 14px;
                margin: 3px 12px 3px 0;
                font-weight: 500;

                &::before {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                }
            }
            &__invulveld_antwoord {
                white-space: nowrap;
                margin-right:10px;
            }
            &.v-meerkeuze-invulveld.hide {
                    display: none !important;
            }

            &__input {
                width: 200px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                border: 1px solid #ced4da;
                margin-left: 10px;
            }
        }
    }

    .v-meerkeuze-aanvullende.hide {
        display: none !important;
    }

    .v-meerkeuze-input {
        line-height: 30px;
        height: 30px;
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        border: 1px solid #ced4da;
        border-radius: 3px;
    }
}

.properties[data-soort="meerkeuze"] {
    label {
        color: $black;
        font-weight: bolder;
        font-size: 13px;
    }

    .meerkeuze-items-header {
        font-weight: 500;
        border-bottom: 1px solid #bbbbbb;

    }

    .meerkeuze-item {
        border-bottom: 1px solid #bbbbbb;
        padding: 6px 0;

        .form-check-inline {
            margin: 0 4px 0 13px;

            .form-check-input {
                margin-right: 0;
            }
        }
    }
}

