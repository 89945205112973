@import 'variables';

.bk-btn {
    display: inline-block;
    line-height: 24px;
    padding: 0 19px;
    background-color: #009ede;
    color: #ffffff;
    text-transform: uppercase;
    font-size: 13px;
    font-weight: 600;
    border-radius: 3px;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.15);
    transition: background-color, color 200ms ease-in-out;
    margin-left: 8px;
    user-select: none;

    &:hover {
        background-color: #ffffff;
        color: #009ede;
    }

    &--primary {
        background-color: #009ede;
        color: #ffffff;

        &:hover {
            background-color: #ffffff;
            color: #009ede;
        }
    }

    &--secondary {
        background-color: #ffffff;
        color: #009ede;

        &:hover {
            background-color: #009ede;
            color: #ffffff;
        }
    }
}


.bk-btn-text {
    color:#2a2a29;
    cursor:pointer;
    border: 1px solid #e5f5fc;
    padding: 0px 10px;
    margin: 5px 0px;
    margin-right:15px;
    border-radius: 15px;
}

.bk-btn-text:hover {
    color:#2a2a29;
    border:1px solid #009ede;
}

.bk-btn-text a, .bk-btn-text a:hover  {
    color: #2a2a29;
}
.bk-btn-text i {
    margin-left:5px;
    color:#009ede;
}
.bk-btn-text--red i {
    color:#e94f35;
}