@import 'variables';

.bk-list {
    a {
        text-decoration: none;
        color: $black;
    }

    &__item {
        display: flex;
        flex: 1 1 100%;
        align-items: center;
        user-select: none;
        border-bottom: 1px solid #eeeeee;

        &:hover {
            background-color: $bk-grey;
            cursor: pointer;

            .bk__item__title {
                color: $black;
            }
        }

        &__title {
            flex-grow: 1;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            padding: 0;
            color: #2a2a29;
            font-size: 14px;
            font-weight: 600;
            line-height: 35px;
        }

        &__icon {
            color: #009ede;

            &--left {
                font-size: 14px;
                padding: 0 0 0 5px;
            }

            &--right {
                font-size: 12px;
                padding: 0 5px 0 0;
            }
        }
    }
}
