.bk-nav {
    font-family: "Source Sans Pro";
    border-right: 2px solid #9d9d9c;

    a {
        text-decoration: none;
    }

    .bk-icon {
        font-size: 14px;
        line-height: 14px;
    }

    .bk-nav__item {
        //level 1
        background-color: #eeeeee;
        border-bottom: 1px solid #cacac9;

        &--last {
            border-bottom-width: 0px;
        }

        &:hover {
        }

        > .bk-nav__item__title {
            font-size: 15px;
            line-height: 32px;
            font-weight: 700;
            color: #2a2a29;
            text-transform: uppercase;
        }

        &--current {
            .bk-nav__item__title {
                color: #009ede;
            }
        }

        &__icon {
            .bk-icon:hover {
            }
        }
    }

    .bk-nav__group__items {
        .bk-nav__item {
            //level 2
            background-color: #ffffff;
            border-bottom: 1px solid #eeeeee;

            &--last {
                border-bottom-width: 1px;
            }

            &:hover {
            }

            > .bk-nav__item__title {
                font-size: 14px;
                font-weight: 600;
                text-transform: none;
                padding-left: 25px;
            }
        }

        .bk-nav__group__items {
            .bk-nav__item {
                //level 3
                > .bk-nav__item__title {
                    padding-left: 35px;
                }
            }

            .bk-nav__group__items {
                .bk-nav__item {
                    //level 4 and up
                    > .bk-nav__item__title {
                        padding-left: 45px;
                    }
                }
            }
        }
    }


    &__group {
        .bk-icon--chevron {
            transition: transform .3s ease-in-out;
            transform: rotate(0deg);

            i {
                font-size: 14px;
                line-height: 14px;

            }
        }

        &--open {
            > .bk-nav__group__header {
                .bk-icon--chevron {
                    transform: rotate(90deg);
                }
            }

            .bk-nav__item {
                border-bottom-width: 0;
            }
        }

        &__header {
            cursor: pointer;
        }
    }

    &__item {
        display: flex;
        flex: 1 1 100%;
        align-items: center;

        &__title {
            flex-grow: 1;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            padding: 5px 6px 5px 0;
        }

        &__icon {
            &--left {
                padding: 0 0 0 14px;
                color: #009ede;
            }

            &--right {
                padding: 0 14px 0 0;
                color: #2a2a29;
            }
        }
    }
}

