@import 'variables';

.panel {
    height: 100%;
    padding: 0 !important;

    &__title {
        font-size: 14px;
        font-weight: 600;
        text-transform: uppercase;
        line-height: 34px;
    }

    &__left {
        position: relative;
        background-color: #ffffff;
        height: 100%;
        width: $bk-panel__left--width;
        overflow-y: auto;

        .panel__title {
            background-color: #009ede;
            color: #ffffff;
            padding: 0 17px;
        }
    }

    &__middle {
        position: relative;
        background-color: #ffffff;
        height: 100%;
        width: calc(100vw - #{$bk-app__left--width} - #{$bk-app__main__padding-x} - #{$bk-app__main__padding-x} - #{$bk-panel__left--width} - #{$bk-panel__gutter--width} - #{$bk-panel__gutter--width} - #{$bk-panel__right--width});
        flex-grow: 1;
        flex-shrink: 1;
        min-width: 0;
        padding: 30px 40px;
        overflow-y: auto;
        overflow-x: hidden;

        .dropzone {
            margin-top: -30px;
            border: 1px solid #eeeeee;
            padding: 10px;
            border-radius: 10px;
            text-align: center;
            color: #888888;
        }

        .vragen {
            padding-bottom: 56px;
        }

        .vraag {
            position: relative;
            border: 1px solid #fff;
            background-color: #fff;
            border-radius: 10px;
            padding: 5px;
            padding-top: 0px;
            height: auto !important;
            width: 100% !important;
            min-height: 30px;
            margin-bottom:20px;

            &__body {
                display: flex;
                flex-direction: row;
                flex-wrap: nowrap;
                padding-top: 10px;
            }

            &__nummer {
                line-height: 30px;
                padding-right: 5px;
                margin-top: 9px;
            }

            &__content {
                margin-top: 14px;
                width: 100%;
            }

            &__actions {
                display: flex;
                opacity: 0;
                position: absolute;
                font-size: 14px;
                top: -19px;
                right: 0px;
                left: 0px;
                justify-content: space-between;
                transition: opacity 50ms ease-in-out;

                &__right {
                    align-self: end;
                }

            }


            &:hover {
                border-color: #009ede;

                .vraag__actions {
                    opacity: 1;
                }
            }

            &--selected {
                border-color: #009ede !important;
            }

            &__titel {
                font-size: 15px;
                font-weight: 600;

            }

            &__vraagstelling {
                font-size: 14px;
                font-weight: 400;
                line-height: 21px;

                p:last-child {
                    margin-bottom: 0;
                }
            }

            &__id {
                position: absolute;
                top: 5px;
                right: 5px;
                font-size: 10px;
                line-height: 10px;
            }

            &[data-soort="uitleg"] {
                min-height: 30px;
            }

            &[data-soort="woord"] {
                .editor {
                    margin-top: -5px;
                    line-height: 30px;
                    min-height: 30px;
                    width: 100%;

                    .woord-item {
                        &:focus {
                            outline: none;
                        }

                        &.active {
                            border-color: $black !important;
                            border-width: 2px;
                        }
                    }
                }
            }

            .veld {
                width: 100px;
                line-height: 20px;
            }

            .veld-klein {
                width: 100px;
                line-height: 20px;
            }

            .veld-middel {
                width: 200px;
                line-height: 20px;
            }

            .veld-groot {
                width: 300px;
                line-height: 20px;
            }

            .veld-meerdere-regels {
                width: 100%;
                min-height: 46px;
            }
        }
        .alwaysactive {
            opacity:1 !important;
        }
        .mainvraag {
            border-color: #009ede;
            margin-bottom:10px;
        }
    }


    &__right {
        position: relative;
        height: 100%;
        width: $bk-panel__right--width;
        background-color: #ffffff;
        overflow-y: auto;

        .panel__title {
            background-color: #555554;
            color: #ffffff;
            padding: 0 20px;
        }

        .properties {
            display: none;

            &--show {
                display: block;
            }
        }
    }

    &__gutter {
        width: $bk-panel__gutter--width;
        height: 100%;
        background-color: #eeeeee;
        position: relative;
    }
}

.bk-nav-small {
    .panel {
        .panel__middle {
            width: calc(100vw - #{$bk-app__left--width-small} - #{$bk-app__main__padding-x} - #{$bk-app__main__padding-x} - #{$bk-panel__left--width} - #{$bk-panel__gutter--width} - #{$bk-panel__gutter--width} - #{$bk-panel__right--width});
        }
    }
}


.wrap {
    flex-wrap: wrap !important;
}