@import 'variables';

.v-schema {
    display: grid;
    grid-row-gap: 1px;
    grid-column-gap: 1px;
    background-color: #bbbbbb;
    //width: fit-content;
    border: 1px solid #bbbbbb;
    width: fit-content;

    &__container {
        overflow-x: auto;
    }

    &__cel {
        position: relative;
        background-color: $white;
        text-align: center;

        &:hover {
            cursor: pointer;
        }

        &.v-schema__cel--selected {
            background-color: #eeeeee;
        }

        &.v-schema__cel--current {
            background-color: #cccccc;
        }

        &.titel input {
            font-weight: 500;
            background-color: #e7e7e7;
        }

        &.antwoorden input {
            background-color: #f9f9f9;
        }

        &.voorbeeldantwoorden input {
            background-color: #f1f1f1;
        }

        &__content {
            color: $black;
            position: relative;
            font-size: 14px;
            width: 100%;
            height: 100%;

            input {
                width: 100%;
                height: 100%;
                border-width: 0px;
                padding: 0 5px;

                :focus-visible {
                    border-radius: 0;
                }
            }
        }

        &__debug {
            position: absolute;
            right: 0;
            bottom: 0;
            font-size: 10px;
            line-height: 10px;
            color: #bbbbbb;
            display: block;
        }
    }

    &__titels {
        background-color: #e7e7e7;
        padding: 5px;
        border: 1px solid #bbbbbb;
    }

    &__antwoorden {
        background-color: #f9f9f9;
        padding: 5px;
        margin-left: 15px;
        border: 1px solid #bbbbbb;
    }

    &__voorbeeldantwoorden {
        background-color: #f1f1f1;
        padding: 5px;
        margin-left: 15px;
        border: 1px solid #bbbbbb;
    }
}

