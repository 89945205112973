@import 'variables';

.gekoppeld-opdracht-items-container {
    margin-top: 16px;

    .gekoppeld-opdracht-item {
        margin-bottom: 12px;

        .form-group {
            margin-bottom: 0;
        }
    }
}
