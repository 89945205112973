@import 'variables';

.v-kruiswoordpuzzel {
    display: grid;
    grid-row-gap: 1px;
    grid-column-gap: 1px;
    background-color: #bbbbbb;
    width: fit-content;
    border: 1px solid #bbbbbb;
    margin-top: 20px;

    &__container {
        overflow-x: auto;
    }

    &__cel {
        position: relative;
        width: 40px;
        height: 40px;
        background-color: $black;
        text-align: center;

        &:not([data-letter="@"]) {
            background-color: $white;

            &:hover {
                cursor: pointer;
            }

            &.v-kruiswoordpuzzel__cel--selected {
                background-color: #eeeeee;
            }

            &.v-kruiswoordpuzzel__cel--current {
                background-color: #cccccc;
            }
        }

        &[data-letter="@"] {
            .v-kruiswoordpuzzel__cel__letter {
            }
        }

        &--fout {
            .v-kruiswoordpuzzel__cel__fout {
                border: 1px solid red;
            }
        }

        &__debug {
            display: none;
            position: absolute;
            right: 0;
            bottom: 0;
            font-size: 10px;
            line-height: 10px;
            color: #bbbbbb
        }

        &__nummer {
            position: absolute;
            top: 2px;
            left: 2px;
            font-size: 10px;
            line-height: 10px;
        }

        &__fout {
            position: absolute;
            top: 1px;
            right: 1px;
            bottom: 1px;
            left: 1px;
        }

        &__letter {
            color: $black;
            position: relative;
            font-size: 20px;
            line-height: 40px;
            width: 40px;
            height: 40px;
            text-transform: uppercase;

            input {
                width: 100%;
                border-width: 0px;
            }
        }
    }
}

.kruiswoordpuzzel {
    &__nummer {
        line-height: 24px;
        margin-bottom: 16px;
        margin-right: 8px;
        width: 20px;
        color: $black;
        text-align: right;


    }
}

