@import 'variables';

.v-volgorde {
    margin-top: 12px;

    &__item {
        margin-bottom: 12px;

        &__left {
            min-width: 200px;
            border: 1px solid #bbbbbb;
            border-radius: 3px;
            padding:5px 10px;

            &.focus {
                font-weight: 600;
            }
        }
    }
}
