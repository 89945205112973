@import 'variables';

.break {
    flex-basis: 100%;
    height: 0;
}

.bk-guest {
    &__modal {
        width: 500px;
        margin: 50px auto 0;
    }
}

.bk-app {
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: stretch;
}

.bk-app__left {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: #555554;
    position: fixed;
    top: 0;
    width: $bk-app__left--width;
    height: 100vh;
    transform: translateX(0%);

    &__small {
        display: none;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #555554;
        z-index: 1;

        &__icon {
            color: #ffffff;
            margin: 14px 0 0 4px;
        }
    }

    &__header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px 0;

        &__icon {
            color: #ffffff;
            margin-right: 14px;
        }

        &__title {
            color: #ffffff;
            font-size: 32px;
            line-height: 38px;
            font-weight: 800;
            margin-left: 25px;
        }
    }

    &__nav {
        background-color: #555554;
        flex-grow: 1;
        overflow-y: auto;
    }

    &__user {
        color: #ffffff;
        padding: 8px 8px 8px 25px;
    }

    &.has-bk-app__header {
        top: $bk-app__header--height;
        max-height: calc(100% - 64px);
    }
}

.bk-nav-small {
    .bk-app__left__small {
        display: block;
    }
}

.bk-app__header {
    //height: $bk-app__main__header--height;
    background: #ffffff;
    flex-shrink: 0;
    padding: 20px $bk-app__main__padding-x 15px;
    margin-right: $bk-app-scrollbar-width;
    border-bottom-width: 0px !important;
    border-bottom-color: #e2e8f0 !important;
    border-bottom-style: solid !important;

    h1 {
        line-height: 38px; //btn height
        margin: 0;
    }
}

.bk-app__main {
    flex-grow: 1;
    //flex-shrink: 1;
    overflow-y: auto;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: stretch;
    align-content: stretch;
    margin-left: $bk-app__left--width; /* .nav width */
    position: relative; /* aside inside .middle */
    /* position: static; aside inside body */
    //overflow-x: hidden; /* datatable issue */
    background-color: #eeeeee;
    //transition: margin-left 50ms ease-in-out;
}

.bk-nav-small .bk-app__main {
    margin-left: $bk-app__left--width-small;
}

.bk-app__footer {
    height: $bk-app__footer--height;
    margin-left: $bk-app__left--width; /* .nav width */
    flex-shrink: 0;
}

.bk-app__main__left {
    width: $bk-app__main__left--width;
    overflow-y: auto;
}

.bk-app__main__middle {
    flex-grow: 1;
    //flex-shrink: 1;
    display: flex;
    flex-direction: column;
    align-items: stretch;
}

.bk-app-main--toggle {
    position: fixed;
    top: 25px;
    left: $bk-app__left--width;
    transform: translateX(-50%);
    background-color: $white;
}

.bk-nav-small  {
    .bk-app-main--toggle {
        left: $bk-app__left--width-small;
    }
}

.bk-app__main__header {
    //height: $bk-app__main__header--height;
    flex-shrink: 0;
    padding: 16px $bk-app__main__padding-x 16px;
    margin-right: $bk-app-scrollbar-width;
    border-bottom-width: 0px !important;
    border-bottom-color: #e2e8f0 !important;
    border-bottom-style: solid !important;

    &__title {
        line-height: 24px; //btn height
        font-size: 16px;
        font-weight: 700;
        color: #2a2a29;

    }
}

.bk-app__main__content {
    overflow-y: auto;
    flex-grow: 1;
    flex-shrink: 1;
    margin: 0px $bk-app__main__padding-x;

    >.scroll-content > div,
    >div:not(.scroll-content):not(.scrollbar-track) {
        padding: 20px 25px 20px 25px;
        background-color: #ffffff;
        box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.15);
    }
}

.bk-app__main__footer {
    //height: $bk-app__main__footer--height;
    flex-shrink: 0;
    padding: 10px $bk-app__main__padding-x;
    margin-right: $bk-app-scrollbar-width;
}

.bk-app__main__right {
    width: $bk-app__main__right--width;
    overflow-y: auto;
}

.bk-app__modal {
    overflow-y: auto;
    background-color: #ffffff;
    position: fixed;
    top: 0px;
    bottom: 0px;
    transition: 0.5s;
    //visibility: hidden;

    &--type2 {
     transition: none;
        top: 50px;
        bottom: auto;
    }

    &--show {
        right: 0 !important;

        &.bk-app__modal--type2 {
            right: calc(0px + #{$bk-app__left--width/2}) !important;
        }
    }

    &__overlay {
        display: block; /* Hidden by default */
        position: fixed; /* Stay in place */
        left: 0;
        top: 0;
        width: 0; /* Full width */
        height: 100%; /* Full height */
        overflow: hidden; /* Disable scroll if needed */
        background-color: rgb(0, 0, 0); /* Fallback color */
        transition: opacity 0.15s linear;
        opacity: 0;

        &--show {
            //display:block;
            opacity: .5;
            width: 100%; /* Full width */
        }
    }

    .bk-app__main {
        margin-left: 0;
        height: 100%;
    }
}

.bk-app__modal_1 {
    right: calc(-100vw + #{$bk-app__left--width});
    width: calc(100vw - #{$bk-app__left--width});
}

.bk-nav-small  {
    .bk-app__modal_1 {
        right: calc(-100vw + #{$bk-app__left--width-small});
        width: calc(100vw - #{$bk-app__left--width-small});
    }
}

.bk-app__modal_2 {
    right: calc(-100vw + #{$bk-app__left--width} - 125px);
    width: calc(100vw - #{$bk-app__left--width} - 125px);
}

.bk-nav-small  {
    .bk-app__modal_2 {
        right: calc(-100vw + #{$bk-app__left--width-small} - 125px);
        width: calc(100vw - #{$bk-app__left--width-small} - 125px);
    }
}

.bk-app__modal_3 {
    right: calc(-100vw + #{$bk-app__left--width} - 250px);
    width: calc(100vw - #{$bk-app__left--width} - 250px);
}

.bk-nav-small  {
    .bk-app__modal_3 {
        right: calc(-100vw + #{$bk-app__left--width-small} - 250px);
        width: calc(100vw - #{$bk-app__left--width-small} - 250px);
    }
}

.bk-app__modal_4 {
    right: calc(-100vw + #{$bk-app__left--width} - 375px);
    width: calc(100vw - #{$bk-app__left--width} - 375px);
}

.bk-nav-small  {
    .bk-app__modal_4 {
        right: calc(-100vw + #{$bk-app__left--width-small} - 375px);
        width: calc(100vw - #{$bk-app__left--width-small} - 375px);
    }
}

/* width */
::-webkit-scrollbar {
    width: 8px;
    background-color: #ffffff;
}

/* Track */
::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #c1c1c1;
    border-radius: 0px;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: rgb(193, 193, 193);
    border-radius: 0px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #a8a8a8;
}

body.demo {
    .bk-app {
        background: #0020d3;
    }

    .bk-app__left {
        background: #fafafa;
    }

    .bk-app__header {
        background: #d40909;
    }

    .bk-app__footer {
        background: #fffb00;
    }

    .bk-app__main__left {
        background: #008cff;
    }

    .bk-app__main {
        background: #b86637;
    }

    .bk-app__main__header {
        background: #ffffff;
    }

    .bk-app__main__content {
        overflow-y: auto;
    }

    .bk-app__main__footer {
        background: #ffffff;
    }

    .bk-app__main__right {
        background: #f321ad;
    }

    .bk-app__modal {
        background: #ffffff;
    }
}

.bk-app__main__content.has-overflow-y {
    /*SP: padding-right: calc($bk-app__main__padding-x - #{$bk-app-scrollbar-width}); */
}

.scrollbar-track {
    transition: opacity 200ms ease-in-out !important;

    .scrollbar-thumb {
        background-color: rgba(0,0,0,0.06) !important;
    }
}

[data-scrollbar]:hover {
    .scrollbar-track {
        background-color: rgba(0,0,0,0.03) !important;
        opacity: 1;

        &.scrollbar-track-x {
            //bottom: 0px; //TODO
        }
    }
}
