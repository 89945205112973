@import 'variables';

.v-combinatie {
    margin-top: 12px;

    &__item {
        margin-bottom: 12px;

        &__left {
            width: 200px;
            border: 1px solid #bbbbbb;
            padding:5px 10px;
            border-radius: 3px;
            margin-right:20px;

            &.focus {
                font-weight: 600;
            }
        }

        &__right {
            width: 200px;
            border: 1px solid #bbbbbb;
            padding:5px 10px;
            border-radius: 3px;
        }
    }
}
