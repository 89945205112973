ul.s-l,
ul#s-l-base {
    padding: 0;
    background-color: #ffffff;

        .title {
            width: 350px;
        }
        .soort {
            width: 250px;
        }
        .col-small {
            width: 150px;
        }
        .col-small i:not(:last-child) {
            margin-right:8px;
        }

    .leerlijn {
        width: 12px;
        height: 12px;
        border-radius: 30rem;
        border:1px solid;
    }

    .leerlijn:not(:last-child) {
        margin-right: 0.5rem;
    }

    ul {
        padding: 0;

        .title {
            width: 300px;
        }

        ul {
            padding: 0;

            .title {
                width: 250px;
            }

            ul {
                padding: 0;

                .title {
                    width: 200px;
                }
                ul {
                    padding: 0;

                    .title {
                        width: 150px;
                    }
                }
            }
        }
    }

    li {
        list-style-type: none;
        //color: #1a202e;
        padding-left: 50px;
        border-top: 1px solid #e2e8f0;
        border-bottom: 1px solid #e2e8f0;
        background-color: #fefefe;
        margin-bottom: -1px;
        position: relative;

        &:after {
            position: absolute;
            left: 10px;
            top: 18px;
            transform: translate(-50%, -50%);
            content: '\e099';
            font-family: "Font Awesome 6 Pro";
            font-weight: 400;
            font-size: 14px;
            cursor: grab;
        }

        > div:hover {
            background-color:aqua;
        }

        > div {
            background-color: #fefefe;
            position: relative;

            .actions {
                margin-left: auto;
            }

            .text {
                line-height: 32px;
                font-size: 14px;
                font-weight: 400;
                padding: 5px;
            }

            .btn {
                margin-left: 12px;
            }
        }
    }

    .s-l-open,
    .s-l-closed {
        >div >span.s-l-opener {
            display: block !important;;
        }
    }

    span.s-l-opener {
        display: none !important;
        position: absolute;
        top: 50%;
        left: -22px;
        transform: translateY(-50%);
        font-size: 14px;
        font-weight: 400;
        cursor: pointer;
    }
}

.s-l-header {
    background-color: #fefefe;
    position: relative;
    line-height: 20px;
    font-size: 14px;
    font-weight: 700;
    padding: 0 0 16px;

    .title {
        width: 350px;
        margin-left:50px;
    }
    .soort {
        width: 250px;
    }
    .col-small {
        width: 150px;
        text-align:center;
    }
    .col-small i:not(:last-child) {
        margin-right:8px;
    }

}

.sortable-handle {
    cursor: grab !important;
}
