@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Abril+Fatface&family=Kalam:wght@300;400;700&family=Merriweather:ital,wght@0,400;0,700;0,900;1,400;1,700;1,900&family=Nixie+One&family=Patrick+Hand&family=Poppins:ital,wght@0,400;0,500;0,600;0,700;0,800;0,900;1,400;1,500;1,600;1,700;1,800;1,900&family=Zilla+Slab:ital,wght@0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&display=swap");
.bk-btn {
  display: inline-block;
  line-height: 24px;
  padding: 0 19px;
  background-color: #009ede;
  color: #ffffff;
  text-transform: uppercase;
  font-size: 13px;
  font-weight: 600;
  border-radius: 3px;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.15);
  transition: background-color, color 200ms ease-in-out;
  margin-left: 8px;
  user-select: none;
}
.bk-btn:hover {
  background-color: #ffffff;
  color: #009ede;
}
.bk-btn--primary {
  background-color: #009ede;
  color: #ffffff;
}
.bk-btn--primary:hover {
  background-color: #ffffff;
  color: #009ede;
}
.bk-btn--secondary {
  background-color: #ffffff;
  color: #009ede;
}
.bk-btn--secondary:hover {
  background-color: #009ede;
  color: #ffffff;
}

.bk-btn-text {
  color: #2a2a29;
  cursor: pointer;
  border: 1px solid #e5f5fc;
  padding: 0px 10px;
  margin: 5px 0px;
  margin-right: 15px;
  border-radius: 15px;
}

.bk-btn-text:hover {
  color: #2a2a29;
  border: 1px solid #009ede;
}

.bk-btn-text a, .bk-btn-text a:hover {
  color: #2a2a29;
}

.bk-btn-text i {
  margin-left: 5px;
  color: #009ede;
}

.bk-btn-text--red i {
  color: #e94f35;
}

.bk-icon {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-color: transparent;
  transition: background-color 200ms ease;
  cursor: pointer;
  font-size: 16px;
  line-height: 16px;
  user-select: none;
}
.bk-icon--small {
  width: 24px;
  height: 24px;
}
.bk-icon:hover {
  background-color: #f1f1f1;
}
.bk-icon--no-hover:hover {
  background-color: transparent;
}
.bk-icon--remove {
  color: #e94f35;
}
.bk-icon--add {
  color: #009ede;
}
.bk-icon--grab {
  cursor: grab;
}
.bk-icon--outline {
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}
.bk-icon--outline i {
  font-size: 10px;
}
.bk-icon__svg:after {
  content: "";
  position: absolute;
  width: 20px;
  height: 20px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-image: url("/svg/sample.svg");
  background-size: cover;
}
.bk-icon__rectangle-small {
  border: 1px solid #cccccc;
  border-radius: 2px;
  width: 40px;
  height: 20px;
  line-height: 1;
}
.bk-icon__rectangle-medium {
  transform: scale(2, 1);
}
.bk-icon__rectangle-large {
  transform: scale(2.5, 1);
}
.bk-icon__rectangle-multiple {
  transform: scale(2.5, 2);
}

.panel__middle .bk-icon {
  border: 1px solid #009ede;
  height: 32px;
  background-color: #fff;
}

.bk-list a {
  text-decoration: none;
  color: black;
}
.bk-list__item {
  display: flex;
  flex: 1 1 100%;
  align-items: center;
  user-select: none;
  border-bottom: 1px solid #eeeeee;
}
.bk-list__item:hover {
  background-color: #eef0ef;
  cursor: pointer;
}
.bk-list__item:hover .bk__item__title {
  color: black;
}
.bk-list__item__title {
  flex-grow: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 0;
  color: #2a2a29;
  font-size: 14px;
  font-weight: 600;
  line-height: 35px;
}
.bk-list__item__icon {
  color: #009ede;
}
.bk-list__item__icon--left {
  font-size: 14px;
  padding: 0 0 0 5px;
}
.bk-list__item__icon--right {
  font-size: 12px;
  padding: 0 5px 0 0;
}

.bk-nav {
  font-family: "Source Sans Pro";
  border-right: 2px solid #9d9d9c;
}
.bk-nav a {
  text-decoration: none;
}
.bk-nav .bk-icon {
  font-size: 14px;
  line-height: 14px;
}
.bk-nav .bk-nav__item {
  background-color: #eeeeee;
  border-bottom: 1px solid #cacac9;
}
.bk-nav .bk-nav__item--last {
  border-bottom-width: 0px;
}
.bk-nav .bk-nav__item > .bk-nav__item__title {
  font-size: 15px;
  line-height: 32px;
  font-weight: 700;
  color: #2a2a29;
  text-transform: uppercase;
}
.bk-nav .bk-nav__item--current .bk-nav__item__title {
  color: #009ede;
}
.bk-nav .bk-nav__group__items .bk-nav__item {
  background-color: #ffffff;
  border-bottom: 1px solid #eeeeee;
}
.bk-nav .bk-nav__group__items .bk-nav__item--last {
  border-bottom-width: 1px;
}
.bk-nav .bk-nav__group__items .bk-nav__item > .bk-nav__item__title {
  font-size: 14px;
  font-weight: 600;
  text-transform: none;
  padding-left: 25px;
}
.bk-nav .bk-nav__group__items .bk-nav__group__items .bk-nav__item > .bk-nav__item__title {
  padding-left: 35px;
}
.bk-nav .bk-nav__group__items .bk-nav__group__items .bk-nav__group__items .bk-nav__item > .bk-nav__item__title {
  padding-left: 45px;
}
.bk-nav__group .bk-icon--chevron {
  transition: transform 0.3s ease-in-out;
  transform: rotate(0deg);
}
.bk-nav__group .bk-icon--chevron i {
  font-size: 14px;
  line-height: 14px;
}
.bk-nav__group--open > .bk-nav__group__header .bk-icon--chevron {
  transform: rotate(90deg);
}
.bk-nav__group--open .bk-nav__item {
  border-bottom-width: 0;
}
.bk-nav__group__header {
  cursor: pointer;
}
.bk-nav__item {
  display: flex;
  flex: 1 1 100%;
  align-items: center;
}
.bk-nav__item__title {
  flex-grow: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 5px 6px 5px 0;
}
.bk-nav__item__icon--left {
  padding: 0 0 0 14px;
  color: #009ede;
}
.bk-nav__item__icon--right {
  padding: 0 14px 0 0;
  color: #2a2a29;
}

* {
  /*margin: 0;
  padding: 0;*/
}

table {
  border-collapse: unset;
}

dl, ol, ul {
  padding: revert;
}

body {
  overflow: hidden;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 14px;
  line-height: 20px;
  color: #2a2a29;
}
body a:hover {
  text-decoration: none;
}

NOT.container,
NOT.container-fluid {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.dataTables_custom_top label {
  margin: 0 !important;
}

.clickable {
  cursor: pointer;
}

.ck-rounded-corners .ck.ck-editor__top .ck-sticky-panel .ck-toolbar {
  border-radius: 0.25rem 0.25rem 0 0 !important;
}

.ck-rounded-corners .ck.ck-editor__main > .ck-editor__editable, .ck.ck-editor__main > .ck-editor__editable.ck-rounded-corners {
  border-radius: 0 0 0.25rem 0.25rem !important;
}

.break {
  flex-basis: 100%;
  height: 0;
}

.bk-guest__modal {
  width: 500px;
  margin: 50px auto 0;
}

.bk-app {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.bk-app__left {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #555554;
  position: fixed;
  top: 0;
  width: 285px;
  height: 100vh;
  transform: translateX(0%);
}
.bk-app__left__small {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #555554;
  z-index: 1;
}
.bk-app__left__small__icon {
  color: #ffffff;
  margin: 14px 0 0 4px;
}
.bk-app__left__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
}
.bk-app__left__header__icon {
  color: #ffffff;
  margin-right: 14px;
}
.bk-app__left__header__title {
  color: #ffffff;
  font-size: 32px;
  line-height: 38px;
  font-weight: 800;
  margin-left: 25px;
}
.bk-app__left__nav {
  background-color: #555554;
  flex-grow: 1;
  overflow-y: auto;
}
.bk-app__left__user {
  color: #ffffff;
  padding: 8px 8px 8px 25px;
}
.bk-app__left.has-bk-app__header {
  top: 64px;
  max-height: calc(100% - 64px);
}

.bk-nav-small .bk-app__left__small {
  display: block;
}

.bk-app__header {
  background: #ffffff;
  flex-shrink: 0;
  padding: 20px 40px 15px;
  margin-right: 0px;
  border-bottom-width: 0px !important;
  border-bottom-color: #e2e8f0 !important;
  border-bottom-style: solid !important;
}
.bk-app__header h1 {
  line-height: 38px;
  margin: 0;
}

.bk-app__main {
  flex-grow: 1;
  overflow-y: auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: stretch;
  align-content: stretch;
  margin-left: 285px;
  /* .nav width */
  position: relative;
  /* aside inside .middle */
  /* position: static; aside inside body */
  background-color: #eeeeee;
}

.bk-nav-small .bk-app__main {
  margin-left: 40px;
}

.bk-app__footer {
  height: 64px;
  margin-left: 285px;
  /* .nav width */
  flex-shrink: 0;
}

.bk-app__main__left {
  width: 220px;
  overflow-y: auto;
}

.bk-app__main__middle {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.bk-app-main--toggle {
  position: fixed;
  top: 25px;
  left: 285px;
  transform: translateX(-50%);
  background-color: white;
}

.bk-nav-small .bk-app-main--toggle {
  left: 40px;
}

.bk-app__main__header {
  flex-shrink: 0;
  padding: 16px 40px 16px;
  margin-right: 0px;
  border-bottom-width: 0px !important;
  border-bottom-color: #e2e8f0 !important;
  border-bottom-style: solid !important;
}
.bk-app__main__header__title {
  line-height: 24px;
  font-size: 16px;
  font-weight: 700;
  color: #2a2a29;
}

.bk-app__main__content {
  overflow-y: auto;
  flex-grow: 1;
  flex-shrink: 1;
  margin: 0px 40px;
}
.bk-app__main__content > .scroll-content > div,
.bk-app__main__content > div:not(.scroll-content):not(.scrollbar-track) {
  padding: 20px 25px 20px 25px;
  background-color: #ffffff;
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.15);
}

.bk-app__main__footer {
  flex-shrink: 0;
  padding: 10px 40px;
  margin-right: 0px;
}

.bk-app__main__right {
  width: 200px;
  overflow-y: auto;
}

.bk-app__modal {
  overflow-y: auto;
  background-color: #ffffff;
  position: fixed;
  top: 0px;
  bottom: 0px;
  transition: 0.5s;
}
.bk-app__modal--type2 {
  transition: none;
  top: 50px;
  bottom: auto;
}
.bk-app__modal--show {
  right: 0 !important;
}
.bk-app__modal--show.bk-app__modal--type2 {
  right: calc(0px + 142.5px) !important;
}
.bk-app__modal__overlay {
  display: block;
  /* Hidden by default */
  position: fixed;
  /* Stay in place */
  left: 0;
  top: 0;
  width: 0;
  /* Full width */
  height: 100%;
  /* Full height */
  overflow: hidden;
  /* Disable scroll if needed */
  background-color: black;
  /* Fallback color */
  transition: opacity 0.15s linear;
  opacity: 0;
}
.bk-app__modal__overlay--show {
  opacity: 0.5;
  width: 100%;
  /* Full width */
}
.bk-app__modal .bk-app__main {
  margin-left: 0;
  height: 100%;
}

.bk-app__modal_1 {
  right: calc(-100vw + 285px);
  width: calc(100vw - 285px);
}

.bk-nav-small .bk-app__modal_1 {
  right: calc(-100vw + 40px);
  width: calc(100vw - 40px);
}

.bk-app__modal_2 {
  right: calc(-100vw + 285px - 125px);
  width: calc(100vw - 285px - 125px);
}

.bk-nav-small .bk-app__modal_2 {
  right: calc(-100vw + 40px - 125px);
  width: calc(100vw - 40px - 125px);
}

.bk-app__modal_3 {
  right: calc(-100vw + 285px - 250px);
  width: calc(100vw - 285px - 250px);
}

.bk-nav-small .bk-app__modal_3 {
  right: calc(-100vw + 40px - 250px);
  width: calc(100vw - 40px - 250px);
}

.bk-app__modal_4 {
  right: calc(-100vw + 285px - 375px);
  width: calc(100vw - 285px - 375px);
}

.bk-nav-small .bk-app__modal_4 {
  right: calc(-100vw + 40px - 375px);
  width: calc(100vw - 40px - 375px);
}

/* width */
::-webkit-scrollbar {
  width: 8px;
  background-color: #ffffff;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #c1c1c1;
  border-radius: 0px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #c1c1c1;
  border-radius: 0px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #a8a8a8;
}

body.demo .bk-app {
  background: #0020d3;
}
body.demo .bk-app__left {
  background: #fafafa;
}
body.demo .bk-app__header {
  background: #d40909;
}
body.demo .bk-app__footer {
  background: #fffb00;
}
body.demo .bk-app__main__left {
  background: #008cff;
}
body.demo .bk-app__main {
  background: #b86637;
}
body.demo .bk-app__main__header {
  background: #ffffff;
}
body.demo .bk-app__main__content {
  overflow-y: auto;
}
body.demo .bk-app__main__footer {
  background: #ffffff;
}
body.demo .bk-app__main__right {
  background: #f321ad;
}
body.demo .bk-app__modal {
  background: #ffffff;
}

.bk-app__main__content.has-overflow-y {
  /*SP: padding-right: calc($bk-app__main__padding-x - 0px); */
}

.scrollbar-track {
  transition: opacity 200ms ease-in-out !important;
}
.scrollbar-track .scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.06) !important;
}

[data-scrollbar]:hover .scrollbar-track {
  background-color: rgba(0, 0, 0, 0.03) !important;
  opacity: 1;
}
ul.s-l,
ul#s-l-base {
  padding: 0;
  background-color: #ffffff;
}
ul.s-l .title,
ul#s-l-base .title {
  width: 350px;
}
ul.s-l .soort,
ul#s-l-base .soort {
  width: 250px;
}
ul.s-l .col-small,
ul#s-l-base .col-small {
  width: 150px;
}
ul.s-l .col-small i:not(:last-child),
ul#s-l-base .col-small i:not(:last-child) {
  margin-right: 8px;
}
ul.s-l .leerlijn,
ul#s-l-base .leerlijn {
  width: 12px;
  height: 12px;
  border-radius: 30rem;
  border: 1px solid;
}
ul.s-l .leerlijn:not(:last-child),
ul#s-l-base .leerlijn:not(:last-child) {
  margin-right: 0.5rem;
}
ul.s-l ul,
ul#s-l-base ul {
  padding: 0;
}
ul.s-l ul .title,
ul#s-l-base ul .title {
  width: 300px;
}
ul.s-l ul ul,
ul#s-l-base ul ul {
  padding: 0;
}
ul.s-l ul ul .title,
ul#s-l-base ul ul .title {
  width: 250px;
}
ul.s-l ul ul ul,
ul#s-l-base ul ul ul {
  padding: 0;
}
ul.s-l ul ul ul .title,
ul#s-l-base ul ul ul .title {
  width: 200px;
}
ul.s-l ul ul ul ul,
ul#s-l-base ul ul ul ul {
  padding: 0;
}
ul.s-l ul ul ul ul .title,
ul#s-l-base ul ul ul ul .title {
  width: 150px;
}
ul.s-l li,
ul#s-l-base li {
  list-style-type: none;
  padding-left: 50px;
  border-top: 1px solid #e2e8f0;
  border-bottom: 1px solid #e2e8f0;
  background-color: #fefefe;
  margin-bottom: -1px;
  position: relative;
}
ul.s-l li:after,
ul#s-l-base li:after {
  position: absolute;
  left: 10px;
  top: 18px;
  transform: translate(-50%, -50%);
  content: "\e099";
  font-family: "Font Awesome 6 Pro";
  font-weight: 400;
  font-size: 14px;
  cursor: grab;
}
ul.s-l li > div:hover,
ul#s-l-base li > div:hover {
  background-color: aqua;
}
ul.s-l li > div,
ul#s-l-base li > div {
  background-color: #fefefe;
  position: relative;
}
ul.s-l li > div .actions,
ul#s-l-base li > div .actions {
  margin-left: auto;
}
ul.s-l li > div .text,
ul#s-l-base li > div .text {
  line-height: 32px;
  font-size: 14px;
  font-weight: 400;
  padding: 5px;
}
ul.s-l li > div .btn,
ul#s-l-base li > div .btn {
  margin-left: 12px;
}
ul.s-l .s-l-open > div > span.s-l-opener,
ul.s-l .s-l-closed > div > span.s-l-opener,
ul#s-l-base .s-l-open > div > span.s-l-opener,
ul#s-l-base .s-l-closed > div > span.s-l-opener {
  display: block !important;
}
ul.s-l span.s-l-opener,
ul#s-l-base span.s-l-opener {
  display: none !important;
  position: absolute;
  top: 50%;
  left: -22px;
  transform: translateY(-50%);
  font-size: 14px;
  font-weight: 400;
  cursor: pointer;
}

.s-l-header {
  background-color: #fefefe;
  position: relative;
  line-height: 20px;
  font-size: 14px;
  font-weight: 700;
  padding: 0 0 16px;
}
.s-l-header .title {
  width: 350px;
  margin-left: 50px;
}
.s-l-header .soort {
  width: 250px;
}
.s-l-header .col-small {
  width: 150px;
  text-align: center;
}
.s-l-header .col-small i:not(:last-child) {
  margin-right: 8px;
}

.sortable-handle {
  cursor: grab !important;
}

body .dataTables_wrapper .dataTables_custom_top {
  display: flex;
  justify-content: space-between;
  margin-bottom: 4px;
}
body .dataTables_wrapper .dataTables_filter input {
  margin: 0;
}
body .dataTables_wrapper .dataTable {
  margin: 0;
}
body .dataTables_wrapper .dataTable thead tr,
body .dataTables_wrapper .dataTable tbody tr {
  border-width: 0 !important;
}
body .dataTables_wrapper .dataTable thead tr th,
body .dataTables_wrapper .dataTable thead tr td,
body .dataTables_wrapper .dataTable tbody tr th,
body .dataTables_wrapper .dataTable tbody tr td {
  border-width: 0 0 1px 0 !important;
  border-bottom-color: #e2e8f0 !important;
  border-bottom-style: solid !important;
  line-height: 32px;
  font-size: 14px;
  font-weight: 400;
  padding: 2px 0 !important;
}
body .dataTables_wrapper .dataTable thead tr th.actions,
body .dataTables_wrapper .dataTable thead tr td.actions,
body .dataTables_wrapper .dataTable tbody tr th.actions,
body .dataTables_wrapper .dataTable tbody tr td.actions {
  text-align: right;
  min-width: 300px;
}
body .dataTables_wrapper .dataTable thead tr th.reorder,
body .dataTables_wrapper .dataTable thead tr td.reorder,
body .dataTables_wrapper .dataTable tbody tr th.reorder,
body .dataTables_wrapper .dataTable tbody tr td.reorder {
  cursor: move;
}
body .dataTables_wrapper .dataTable thead tr th .btn,
body .dataTables_wrapper .dataTable thead tr td .btn,
body .dataTables_wrapper .dataTable tbody tr th .btn,
body .dataTables_wrapper .dataTable tbody tr td .btn {
  margin-left: 12px;
}
body .dataTables_wrapper .dataTable thead tr th,
body .dataTables_wrapper .dataTable tbody tr th {
  font-weight: 700;
  text-transform: uppercase;
}
body .dataTables_wrapper .dataTables_custom_bottom {
  display: flex;
  justify-content: space-between;
  line-height: 38px;
  font-size: 14px;
  margin: 15px 0 0;
}
body .dataTables_wrapper .dataTables_custom_bottom .dataTables_info,
body .dataTables_wrapper .dataTables_custom_bottom .dataTables_paginate {
  padding: 0;
  margin: 0;
}
body .dataTables_wrapper .dataTables_custom_bottom .dataTables_info {
  margin: 12px 0 0;
  width: 200px;
  text-align: right;
  line-height: 16px;
  font-size: 12px;
  font-weight: 400;
  color: #2a2a29;
}
body .dataTables_wrapper .dataTables_custom_bottom .dataTables_custom {
  width: 200px;
}
body .dataTables_wrapper .dataTables_custom_bottom .dataTables_paginate {
  padding: 0;
  margin: 0;
}

.bk-dt-btn {
  border: 1px solid #dfdfdf;
  padding: 0 4px;
  line-height: 16px;
  font-size: 12px;
  font-weight: 400;
  color: #2a2a29;
  margin: 0 4px;
  cursor: pointer;
}
.bk-dt-btn.active {
  background-color: #dfdfdf;
}
.bk-dt-btn.previous, .bk-dt-btn.next {
  border-style: none;
  text-transform: lowercase;
}
.bk-dt-btn:hover {
  color: #009ede;
}

.multiselect-native-select .btn-group {
  width: 100%;
}
.multiselect-native-select .btn-group .multiselect-container {
  border-color: #e2e8f0;
}
.multiselect-native-select .btn-group .multiselect {
  text-align: left !important;
}
.multiselect-native-select .multiselect-container {
  width: 100%;
}

.form-control {
  font-size: 14px;
  line-height: 22px;
  color: #2a2a29;
  padding: 0 10px;
  height: 24px;
  position: relative;
}

textarea.form-control {
  height: 100px !important;
}

.form-group.has-error .validator-message {
  display: block;
}
.form-group.has-error .form-control,
.form-group.has-error .multiselect,
.form-group.has-error .custom-file-label,
.form-group.has-error .fu {
  border-color: #dc3545;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.form-group.has-error .fu-edit {
  display: none;
}
.form-group > label:first-child {
  color: black;
  font-weight: bolder;
  font-size: 13px;
}
.form-group .validator-message {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545;
}

.custom-control-input,
.custom-control-label {
  cursor: pointer !important;
}

.treejs > .treejs-nodes {
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  padding: 5px !important;
  margin-left: 20px !important;
  overflow-y: auto !important;
  max-height: 307px;
}

.fu {
  margin-top: 15px;
  padding: 0.375rem 0.75rem;
  overflow: hidden;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}
.fu-preview {
  width: 128px;
  height: 128px;
}
.fu-preview img {
  max-width: 100%;
  max-height: 100%;
}

.ck-content .table {
  margin: 0.9em 0 !important;
  float: none !important;
}

.ck-content .table table td, .ck-content .table table th,
.ck .ck-content .table table td, .ck .ck-content .table table th {
  border: 0.1px solid #bfbfbf;
}

img {
  display: block;
  /* This rule is very important, please don't ignore this */
  max-width: 100%;
}

.file-edit-container {
  width: 100%;
  NOTheight: calc(100vh - 81px - 25px);
  height: 300px;
}

.bk-breadcrumb {
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding: 0;
  margin: 0;
  list-style: none;
  font-size: 14px;
  font-weight: 600;
  color: #555554;
}
.bk-breadcrumb a {
  color: #555554;
}

.bk-breadcrumb-item + .bk-breadcrumb-item {
  padding-left: 0.5rem;
}

.bk-breadcrumb-item + .bk-breadcrumb-item::before {
  float: left;
  padding-right: 0.5rem;
  color: #555554;
  content: ">";
}

.bk-breadcrumb-item.active {
  color: #555554;
}

.bk-breadcrumb-item + .bk-breadcrumb-item::before {
  float: left;
  padding-right: 0.5rem;
  color: #555554;
  content: ">";
}

.truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

[contenteditable] {
  outline: 0px solid transparent;
}

.cke_notification_warning {
  display: none;
}

.treejs-node {
  padding-bottom: 4px;
}

.bk-accordion .bk-accordion__item .bk-accordion__heading .bk-icon--open {
  display: flex;
}
.bk-accordion .bk-accordion__item .bk-accordion__heading .bk-icon--close {
  display: none;
}
.bk-accordion .bk-accordion__item--open .bk-accordion__heading .bk-icon--open {
  display: none;
}
.bk-accordion .bk-accordion__item--open .bk-accordion__heading .bk-icon--close {
  display: flex;
}
.bk-accordion__heading {
  display: flex;
  font-size: 14px;
  line-height: 36px;
  text-transform: uppercase;
  padding: 0 20px;
  margin-top: 2px;
  background-color: #eeeeee;
  color: #2a2a29;
  cursor: pointer;
  user-select: none;
}
.bk-accordion__heading__title {
  flex-grow: 1;
}
.bk-accordion__heading__icon {
  margin-top: 2px;
}
.bk-accordion__heading__icon .bk-icon {
  font-size: 14px;
}
.bk-accordion__collapse {
  padding: 10px 20px;
  user-select: none;
}

.panel {
  height: 100%;
  padding: 0 !important;
}
.panel__title {
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
  line-height: 34px;
}
.panel__left {
  position: relative;
  background-color: #ffffff;
  height: 100%;
  width: 222px;
  overflow-y: auto;
}
.panel__left .panel__title {
  background-color: #009ede;
  color: #ffffff;
  padding: 0 17px;
}
.panel__middle {
  position: relative;
  background-color: #ffffff;
  height: 100%;
  width: calc(100vw - 285px - 40px - 40px - 222px - 2px - 2px - 450px);
  flex-grow: 1;
  flex-shrink: 1;
  min-width: 0;
  padding: 30px 40px;
  overflow-y: auto;
  overflow-x: hidden;
}
.panel__middle .dropzone {
  margin-top: -30px;
  border: 1px solid #eeeeee;
  padding: 10px;
  border-radius: 10px;
  text-align: center;
  color: #888888;
}
.panel__middle .vragen {
  padding-bottom: 56px;
}
.panel__middle .vraag {
  position: relative;
  border: 1px solid #fff;
  background-color: #fff;
  border-radius: 10px;
  padding: 5px;
  padding-top: 0px;
  height: auto !important;
  width: 100% !important;
  min-height: 30px;
  margin-bottom: 20px;
}
.panel__middle .vraag__body {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  padding-top: 10px;
}
.panel__middle .vraag__nummer {
  line-height: 30px;
  padding-right: 5px;
  margin-top: 9px;
}
.panel__middle .vraag__content {
  margin-top: 14px;
  width: 100%;
}
.panel__middle .vraag__actions {
  display: flex;
  opacity: 0;
  position: absolute;
  font-size: 14px;
  top: -19px;
  right: 0px;
  left: 0px;
  justify-content: space-between;
  transition: opacity 50ms ease-in-out;
}
.panel__middle .vraag__actions__right {
  align-self: end;
}
.panel__middle .vraag:hover {
  border-color: #009ede;
}
.panel__middle .vraag:hover .vraag__actions {
  opacity: 1;
}
.panel__middle .vraag--selected {
  border-color: #009ede !important;
}
.panel__middle .vraag__titel {
  font-size: 15px;
  font-weight: 600;
}
.panel__middle .vraag__vraagstelling {
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
}
.panel__middle .vraag__vraagstelling p:last-child {
  margin-bottom: 0;
}
.panel__middle .vraag__id {
  position: absolute;
  top: 5px;
  right: 5px;
  font-size: 10px;
  line-height: 10px;
}
.panel__middle .vraag[data-soort=uitleg] {
  min-height: 30px;
}
.panel__middle .vraag[data-soort=woord] .editor {
  margin-top: -5px;
  line-height: 30px;
  min-height: 30px;
  width: 100%;
}
.panel__middle .vraag[data-soort=woord] .editor .woord-item:focus {
  outline: none;
}
.panel__middle .vraag[data-soort=woord] .editor .woord-item.active {
  border-color: black !important;
  border-width: 2px;
}
.panel__middle .vraag .veld {
  width: 100px;
  line-height: 20px;
}
.panel__middle .vraag .veld-klein {
  width: 100px;
  line-height: 20px;
}
.panel__middle .vraag .veld-middel {
  width: 200px;
  line-height: 20px;
}
.panel__middle .vraag .veld-groot {
  width: 300px;
  line-height: 20px;
}
.panel__middle .vraag .veld-meerdere-regels {
  width: 100%;
  min-height: 46px;
}
.panel__middle .alwaysactive {
  opacity: 1 !important;
}
.panel__middle .mainvraag {
  border-color: #009ede;
  margin-bottom: 10px;
}
.panel__right {
  position: relative;
  height: 100%;
  width: 450px;
  background-color: #ffffff;
  overflow-y: auto;
}
.panel__right .panel__title {
  background-color: #555554;
  color: #ffffff;
  padding: 0 20px;
}
.panel__right .properties {
  display: none;
}
.panel__right .properties--show {
  display: block;
}
.panel__gutter {
  width: 2px;
  height: 100%;
  background-color: #eeeeee;
  position: relative;
}

.bk-nav-small .panel .panel__middle {
  width: calc(100vw - 40px - 40px - 40px - 222px - 2px - 2px - 450px);
}

.wrap {
  flex-wrap: wrap !important;
}

.vraag[data-soort=begrippennet] .ballonnen {
  position: relative;
  margin-top: 12px;
  width: 100%;
  max-width: 968px;
  margin: auto;
  height: 350px;
}
.vraag[data-soort=begrippennet] .ballonnen .ballon {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  transform: translate(-50%, -50%);
}
.vraag[data-soort=begrippennet] .ballonnen .ballon[data-ballon="0"] {
  z-index: 2;
  top: 50%;
  left: 50%;
}
.vraag[data-soort=begrippennet] .ballonnen .ballon__woord {
  position: relative;
  background-color: #ffffff;
  width: 200px;
  height: 50px;
  border: 1px solid #bbbbbb;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.vraag[data-soort=begrippennet] .ballonnen .ballon__woord.focus {
  border-color: #000000;
}
.vraag[data-soort=begrippennet] .ballonnen .ballon__woord:before {
  content: "";
  z-index: -1;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 1px;
  background-color: #bbbbbb;
  transform-origin: 0% 0%;
}
.vraag[data-soort=begrippennet] .ballonnen[data-aantal_ballonnen="0"] .ballon[data-ballon="1"], .vraag[data-soort=begrippennet] .ballonnen[data-aantal_ballonnen="0"] .ballon[data-ballon="2"], .vraag[data-soort=begrippennet] .ballonnen[data-aantal_ballonnen="0"] .ballon[data-ballon="3"], .vraag[data-soort=begrippennet] .ballonnen[data-aantal_ballonnen="0"] .ballon[data-ballon="4"], .vraag[data-soort=begrippennet] .ballonnen[data-aantal_ballonnen="0"] .ballon[data-ballon="5"], .vraag[data-soort=begrippennet] .ballonnen[data-aantal_ballonnen="0"] .ballon[data-ballon="6"], .vraag[data-soort=begrippennet] .ballonnen[data-aantal_ballonnen="0"] .ballon[data-ballon="7"], .vraag[data-soort=begrippennet] .ballonnen[data-aantal_ballonnen="0"] .ballon[data-ballon="8"], .vraag[data-soort=begrippennet] .ballonnen[data-aantal_ballonnen="0"] .ballon[data-ballon="9"] {
  display: none;
}
.vraag[data-soort=begrippennet] .ballonnen[data-aantal_ballonnen="1"] .ballon[data-ballon="1"] {
  top: 20%;
  left: 50%;
}
.vraag[data-soort=begrippennet] .ballonnen[data-aantal_ballonnen="1"] .ballon[data-ballon="1"] .ballon__woord:before {
  height: 105px;
  transform: rotate(0deg);
}
.vraag[data-soort=begrippennet] .ballonnen[data-aantal_ballonnen="1"] .ballon[data-ballon="2"], .vraag[data-soort=begrippennet] .ballonnen[data-aantal_ballonnen="1"] .ballon[data-ballon="3"], .vraag[data-soort=begrippennet] .ballonnen[data-aantal_ballonnen="1"] .ballon[data-ballon="4"], .vraag[data-soort=begrippennet] .ballonnen[data-aantal_ballonnen="1"] .ballon[data-ballon="5"], .vraag[data-soort=begrippennet] .ballonnen[data-aantal_ballonnen="1"] .ballon[data-ballon="6"], .vraag[data-soort=begrippennet] .ballonnen[data-aantal_ballonnen="1"] .ballon[data-ballon="7"], .vraag[data-soort=begrippennet] .ballonnen[data-aantal_ballonnen="1"] .ballon[data-ballon="8"], .vraag[data-soort=begrippennet] .ballonnen[data-aantal_ballonnen="1"] .ballon[data-ballon="9"] {
  display: none;
}
.vraag[data-soort=begrippennet] .ballonnen[data-aantal_ballonnen="2"] .ballon[data-ballon="1"] {
  top: 20%;
  left: 50%;
}
.vraag[data-soort=begrippennet] .ballonnen[data-aantal_ballonnen="2"] .ballon[data-ballon="1"] .ballon__woord:before {
  height: 105px;
  transform: rotate(0deg);
}
.vraag[data-soort=begrippennet] .ballonnen[data-aantal_ballonnen="2"] .ballon[data-ballon="2"] {
  top: 80%;
  left: 50%;
}
.vraag[data-soort=begrippennet] .ballonnen[data-aantal_ballonnen="2"] .ballon[data-ballon="2"] .ballon__woord:before {
  height: 105px;
  transform: rotate(180deg);
}
.vraag[data-soort=begrippennet] .ballonnen[data-aantal_ballonnen="2"] .ballon[data-ballon="3"], .vraag[data-soort=begrippennet] .ballonnen[data-aantal_ballonnen="2"] .ballon[data-ballon="4"], .vraag[data-soort=begrippennet] .ballonnen[data-aantal_ballonnen="2"] .ballon[data-ballon="5"], .vraag[data-soort=begrippennet] .ballonnen[data-aantal_ballonnen="2"] .ballon[data-ballon="6"], .vraag[data-soort=begrippennet] .ballonnen[data-aantal_ballonnen="2"] .ballon[data-ballon="7"], .vraag[data-soort=begrippennet] .ballonnen[data-aantal_ballonnen="2"] .ballon[data-ballon="8"], .vraag[data-soort=begrippennet] .ballonnen[data-aantal_ballonnen="2"] .ballon[data-ballon="9"] {
  display: none;
}
.vraag[data-soort=begrippennet] .ballonnen[data-aantal_ballonnen="3"] .ballon[data-ballon="1"] {
  top: 20%;
  left: 50%;
}
.vraag[data-soort=begrippennet] .ballonnen[data-aantal_ballonnen="3"] .ballon[data-ballon="1"] .ballon__woord:before {
  height: 105px;
  transform: rotate(0deg);
}
.vraag[data-soort=begrippennet] .ballonnen[data-aantal_ballonnen="3"] .ballon[data-ballon="2"] {
  top: 67.5%;
  left: 72.5%;
}
.vraag[data-soort=begrippennet] .ballonnen[data-aantal_ballonnen="3"] .ballon[data-ballon="2"] .ballon__woord:before {
  height: 150px;
  transform: rotate(108deg);
}
.vraag[data-soort=begrippennet] .ballonnen[data-aantal_ballonnen="3"] .ballon[data-ballon="3"] {
  top: 67.5%;
  left: 27.5%;
}
.vraag[data-soort=begrippennet] .ballonnen[data-aantal_ballonnen="3"] .ballon[data-ballon="3"] .ballon__woord:before {
  height: 150px;
  transform: rotate(252deg);
}
.vraag[data-soort=begrippennet] .ballonnen[data-aantal_ballonnen="3"] .ballon[data-ballon="4"], .vraag[data-soort=begrippennet] .ballonnen[data-aantal_ballonnen="3"] .ballon[data-ballon="5"], .vraag[data-soort=begrippennet] .ballonnen[data-aantal_ballonnen="3"] .ballon[data-ballon="6"], .vraag[data-soort=begrippennet] .ballonnen[data-aantal_ballonnen="3"] .ballon[data-ballon="7"], .vraag[data-soort=begrippennet] .ballonnen[data-aantal_ballonnen="3"] .ballon[data-ballon="8"], .vraag[data-soort=begrippennet] .ballonnen[data-aantal_ballonnen="3"] .ballon[data-ballon="9"] {
  display: none;
}
.vraag[data-soort=begrippennet] .ballonnen[data-aantal_ballonnen="4"] .ballon[data-ballon="1"] {
  top: 32.5%;
  left: 72.5%;
}
.vraag[data-soort=begrippennet] .ballonnen[data-aantal_ballonnen="4"] .ballon[data-ballon="1"] .ballon__woord:before {
  height: 150px;
  transform: rotate(72deg);
}
.vraag[data-soort=begrippennet] .ballonnen[data-aantal_ballonnen="4"] .ballon[data-ballon="2"] {
  top: 67.5%;
  left: 72.5%;
}
.vraag[data-soort=begrippennet] .ballonnen[data-aantal_ballonnen="4"] .ballon[data-ballon="2"] .ballon__woord:before {
  height: 150px;
  transform: rotate(108deg);
}
.vraag[data-soort=begrippennet] .ballonnen[data-aantal_ballonnen="4"] .ballon[data-ballon="3"] {
  top: 67.5%;
  left: 27.5%;
}
.vraag[data-soort=begrippennet] .ballonnen[data-aantal_ballonnen="4"] .ballon[data-ballon="3"] .ballon__woord:before {
  height: 150px;
  transform: rotate(252deg);
}
.vraag[data-soort=begrippennet] .ballonnen[data-aantal_ballonnen="4"] .ballon[data-ballon="4"] {
  top: 32.5%;
  left: 27.5%;
}
.vraag[data-soort=begrippennet] .ballonnen[data-aantal_ballonnen="4"] .ballon[data-ballon="4"] .ballon__woord:before {
  height: 150px;
  transform: rotate(288deg);
}
.vraag[data-soort=begrippennet] .ballonnen[data-aantal_ballonnen="4"] .ballon[data-ballon="5"], .vraag[data-soort=begrippennet] .ballonnen[data-aantal_ballonnen="4"] .ballon[data-ballon="6"], .vraag[data-soort=begrippennet] .ballonnen[data-aantal_ballonnen="4"] .ballon[data-ballon="7"], .vraag[data-soort=begrippennet] .ballonnen[data-aantal_ballonnen="4"] .ballon[data-ballon="8"], .vraag[data-soort=begrippennet] .ballonnen[data-aantal_ballonnen="4"] .ballon[data-ballon="9"] {
  display: none;
}
.vraag[data-soort=begrippennet] .ballonnen[data-aantal_ballonnen="5"] .ballon[data-ballon="1"] {
  top: 20%;
  left: 50%;
}
.vraag[data-soort=begrippennet] .ballonnen[data-aantal_ballonnen="5"] .ballon[data-ballon="1"] .ballon__woord:before {
  height: 105px;
  transform: rotate(0deg);
}
.vraag[data-soort=begrippennet] .ballonnen[data-aantal_ballonnen="5"] .ballon[data-ballon="2"] {
  top: 32.5%;
  left: 72.5%;
}
.vraag[data-soort=begrippennet] .ballonnen[data-aantal_ballonnen="5"] .ballon[data-ballon="2"] .ballon__woord:before {
  height: 150px;
  transform: rotate(72deg);
}
.vraag[data-soort=begrippennet] .ballonnen[data-aantal_ballonnen="5"] .ballon[data-ballon="3"] {
  top: 67.5%;
  left: 72.5%;
}
.vraag[data-soort=begrippennet] .ballonnen[data-aantal_ballonnen="5"] .ballon[data-ballon="3"] .ballon__woord:before {
  height: 150px;
  transform: rotate(108deg);
}
.vraag[data-soort=begrippennet] .ballonnen[data-aantal_ballonnen="5"] .ballon[data-ballon="4"] {
  top: 67.5%;
  left: 27.5%;
}
.vraag[data-soort=begrippennet] .ballonnen[data-aantal_ballonnen="5"] .ballon[data-ballon="4"] .ballon__woord:before {
  height: 150px;
  transform: rotate(252deg);
}
.vraag[data-soort=begrippennet] .ballonnen[data-aantal_ballonnen="5"] .ballon[data-ballon="5"] {
  top: 32.5%;
  left: 27.5%;
}
.vraag[data-soort=begrippennet] .ballonnen[data-aantal_ballonnen="5"] .ballon[data-ballon="5"] .ballon__woord:before {
  height: 150px;
  transform: rotate(288deg);
}
.vraag[data-soort=begrippennet] .ballonnen[data-aantal_ballonnen="5"] .ballon[data-ballon="6"], .vraag[data-soort=begrippennet] .ballonnen[data-aantal_ballonnen="5"] .ballon[data-ballon="7"], .vraag[data-soort=begrippennet] .ballonnen[data-aantal_ballonnen="5"] .ballon[data-ballon="8"], .vraag[data-soort=begrippennet] .ballonnen[data-aantal_ballonnen="5"] .ballon[data-ballon="9"] {
  display: none;
}
.vraag[data-soort=begrippennet] .ballonnen[data-aantal_ballonnen="6"] .ballon[data-ballon="1"] {
  top: 32.5%;
  left: 72.5%;
}
.vraag[data-soort=begrippennet] .ballonnen[data-aantal_ballonnen="6"] .ballon[data-ballon="1"] .ballon__woord:before {
  height: 150px;
  transform: rotate(72deg);
}
.vraag[data-soort=begrippennet] .ballonnen[data-aantal_ballonnen="6"] .ballon[data-ballon="2"] {
  top: 50%;
  left: 82.5%;
}
.vraag[data-soort=begrippennet] .ballonnen[data-aantal_ballonnen="6"] .ballon[data-ballon="2"] .ballon__woord:before {
  height: 200px;
  transform: rotate(90deg);
}
.vraag[data-soort=begrippennet] .ballonnen[data-aantal_ballonnen="6"] .ballon[data-ballon="3"] {
  top: 67.5%;
  left: 72.5%;
}
.vraag[data-soort=begrippennet] .ballonnen[data-aantal_ballonnen="6"] .ballon[data-ballon="3"] .ballon__woord:before {
  height: 150px;
  transform: rotate(108deg);
}
.vraag[data-soort=begrippennet] .ballonnen[data-aantal_ballonnen="6"] .ballon[data-ballon="4"] {
  top: 67.5%;
  left: 27.5%;
}
.vraag[data-soort=begrippennet] .ballonnen[data-aantal_ballonnen="6"] .ballon[data-ballon="4"] .ballon__woord:before {
  height: 150px;
  transform: rotate(252deg);
}
.vraag[data-soort=begrippennet] .ballonnen[data-aantal_ballonnen="6"] .ballon[data-ballon="5"] {
  top: 50%;
  left: 17.5%;
}
.vraag[data-soort=begrippennet] .ballonnen[data-aantal_ballonnen="6"] .ballon[data-ballon="5"] .ballon__woord:before {
  height: 200px;
  transform: rotate(270deg);
}
.vraag[data-soort=begrippennet] .ballonnen[data-aantal_ballonnen="6"] .ballon[data-ballon="6"] {
  top: 32.5%;
  left: 27.5%;
}
.vraag[data-soort=begrippennet] .ballonnen[data-aantal_ballonnen="6"] .ballon[data-ballon="6"] .ballon__woord:before {
  height: 150px;
  transform: rotate(288deg);
}
.vraag[data-soort=begrippennet] .ballonnen[data-aantal_ballonnen="6"] .ballon[data-ballon="7"], .vraag[data-soort=begrippennet] .ballonnen[data-aantal_ballonnen="6"] .ballon[data-ballon="8"], .vraag[data-soort=begrippennet] .ballonnen[data-aantal_ballonnen="6"] .ballon[data-ballon="9"] {
  display: none;
}
.vraag[data-soort=begrippennet] .ballonnen[data-aantal_ballonnen="7"] .ballon[data-ballon="1"] {
  top: 20%;
  left: 50%;
}
.vraag[data-soort=begrippennet] .ballonnen[data-aantal_ballonnen="7"] .ballon[data-ballon="1"] .ballon__woord:before {
  height: 105px;
  transform: rotate(0deg);
}
.vraag[data-soort=begrippennet] .ballonnen[data-aantal_ballonnen="7"] .ballon[data-ballon="2"] {
  top: 32.5%;
  left: 72.5%;
}
.vraag[data-soort=begrippennet] .ballonnen[data-aantal_ballonnen="7"] .ballon[data-ballon="2"] .ballon__woord:before {
  height: 150px;
  transform: rotate(72deg);
}
.vraag[data-soort=begrippennet] .ballonnen[data-aantal_ballonnen="7"] .ballon[data-ballon="3"] {
  top: 50%;
  left: 82.5%;
}
.vraag[data-soort=begrippennet] .ballonnen[data-aantal_ballonnen="7"] .ballon[data-ballon="3"] .ballon__woord:before {
  height: 200px;
  transform: rotate(90deg);
}
.vraag[data-soort=begrippennet] .ballonnen[data-aantal_ballonnen="7"] .ballon[data-ballon="4"] {
  top: 67.5%;
  left: 72.5%;
}
.vraag[data-soort=begrippennet] .ballonnen[data-aantal_ballonnen="7"] .ballon[data-ballon="4"] .ballon__woord:before {
  height: 150px;
  transform: rotate(108deg);
}
.vraag[data-soort=begrippennet] .ballonnen[data-aantal_ballonnen="7"] .ballon[data-ballon="5"] {
  top: 67.5%;
  left: 27.5%;
}
.vraag[data-soort=begrippennet] .ballonnen[data-aantal_ballonnen="7"] .ballon[data-ballon="5"] .ballon__woord:before {
  height: 150px;
  transform: rotate(252deg);
}
.vraag[data-soort=begrippennet] .ballonnen[data-aantal_ballonnen="7"] .ballon[data-ballon="6"] {
  top: 50%;
  left: 17.5%;
}
.vraag[data-soort=begrippennet] .ballonnen[data-aantal_ballonnen="7"] .ballon[data-ballon="6"] .ballon__woord:before {
  height: 200px;
  transform: rotate(270deg);
}
.vraag[data-soort=begrippennet] .ballonnen[data-aantal_ballonnen="7"] .ballon[data-ballon="7"] {
  top: 32.5%;
  left: 27.5%;
}
.vraag[data-soort=begrippennet] .ballonnen[data-aantal_ballonnen="7"] .ballon[data-ballon="7"] .ballon__woord:before {
  height: 150px;
  transform: rotate(288deg);
}
.vraag[data-soort=begrippennet] .ballonnen[data-aantal_ballonnen="7"] .ballon[data-ballon="8"], .vraag[data-soort=begrippennet] .ballonnen[data-aantal_ballonnen="7"] .ballon[data-ballon="9"] {
  display: none;
}
.vraag[data-soort=begrippennet] .ballonnen[data-aantal_ballonnen="8"] .ballon[data-ballon="1"] {
  top: 20%;
  left: 50%;
}
.vraag[data-soort=begrippennet] .ballonnen[data-aantal_ballonnen="8"] .ballon[data-ballon="1"] .ballon__woord:before {
  height: 105px;
  transform: rotate(0deg);
}
.vraag[data-soort=begrippennet] .ballonnen[data-aantal_ballonnen="8"] .ballon[data-ballon="2"] {
  top: 32.5%;
  left: 72.5%;
}
.vraag[data-soort=begrippennet] .ballonnen[data-aantal_ballonnen="8"] .ballon[data-ballon="2"] .ballon__woord:before {
  height: 150px;
  transform: rotate(72deg);
}
.vraag[data-soort=begrippennet] .ballonnen[data-aantal_ballonnen="8"] .ballon[data-ballon="3"] {
  top: 50%;
  left: 82.5%;
}
.vraag[data-soort=begrippennet] .ballonnen[data-aantal_ballonnen="8"] .ballon[data-ballon="3"] .ballon__woord:before {
  height: 200px;
  transform: rotate(90deg);
}
.vraag[data-soort=begrippennet] .ballonnen[data-aantal_ballonnen="8"] .ballon[data-ballon="4"] {
  top: 67.5%;
  left: 72.5%;
}
.vraag[data-soort=begrippennet] .ballonnen[data-aantal_ballonnen="8"] .ballon[data-ballon="4"] .ballon__woord:before {
  height: 150px;
  transform: rotate(108deg);
}
.vraag[data-soort=begrippennet] .ballonnen[data-aantal_ballonnen="8"] .ballon[data-ballon="5"] {
  top: 80%;
  left: 50%;
}
.vraag[data-soort=begrippennet] .ballonnen[data-aantal_ballonnen="8"] .ballon[data-ballon="5"] .ballon__woord:before {
  height: 105px;
  transform: rotate(180deg);
}
.vraag[data-soort=begrippennet] .ballonnen[data-aantal_ballonnen="8"] .ballon[data-ballon="6"] {
  top: 67.5%;
  left: 27.5%;
}
.vraag[data-soort=begrippennet] .ballonnen[data-aantal_ballonnen="8"] .ballon[data-ballon="6"] .ballon__woord:before {
  height: 150px;
  transform: rotate(252deg);
}
.vraag[data-soort=begrippennet] .ballonnen[data-aantal_ballonnen="8"] .ballon[data-ballon="7"] {
  top: 50%;
  left: 17.5%;
}
.vraag[data-soort=begrippennet] .ballonnen[data-aantal_ballonnen="8"] .ballon[data-ballon="7"] .ballon__woord:before {
  height: 200px;
  transform: rotate(270deg);
}
.vraag[data-soort=begrippennet] .ballonnen[data-aantal_ballonnen="8"] .ballon[data-ballon="8"] {
  top: 32.5%;
  left: 27.5%;
}
.vraag[data-soort=begrippennet] .ballonnen[data-aantal_ballonnen="8"] .ballon[data-ballon="8"] .ballon__woord:before {
  height: 150px;
  transform: rotate(288deg);
}
.vraag[data-soort=begrippennet] .ballonnen[data-aantal_ballonnen="8"] .ballon[data-ballon="9"] {
  display: none;
}
.vraag[data-soort=begrippennet] .ballonnen[data-aantal_ballonnen="9"] .ballon[data-ballon="1"] {
  top: 20%;
  left: 50%;
}
.vraag[data-soort=begrippennet] .ballonnen[data-aantal_ballonnen="9"] .ballon[data-ballon="1"] .ballon__woord:before {
  height: 105px;
  transform: rotate(0deg);
}
.vraag[data-soort=begrippennet] .ballonnen[data-aantal_ballonnen="9"] .ballon[data-ballon="2"] {
  top: 32.5%;
  left: 72.5%;
}
.vraag[data-soort=begrippennet] .ballonnen[data-aantal_ballonnen="9"] .ballon[data-ballon="2"] .ballon__woord:before {
  height: 150px;
  transform: rotate(72deg);
}
.vraag[data-soort=begrippennet] .ballonnen[data-aantal_ballonnen="9"] .ballon[data-ballon="3"] {
  top: 50%;
  left: 82.5%;
}
.vraag[data-soort=begrippennet] .ballonnen[data-aantal_ballonnen="9"] .ballon[data-ballon="3"] .ballon__woord:before {
  height: 200px;
  transform: rotate(90deg);
}
.vraag[data-soort=begrippennet] .ballonnen[data-aantal_ballonnen="9"] .ballon[data-ballon="4"] {
  top: 67.5%;
  left: 72.5%;
}
.vraag[data-soort=begrippennet] .ballonnen[data-aantal_ballonnen="9"] .ballon[data-ballon="4"] .ballon__woord:before {
  height: 150px;
  transform: rotate(108deg);
}
.vraag[data-soort=begrippennet] .ballonnen[data-aantal_ballonnen="9"] .ballon[data-ballon="5"] {
  top: 86%;
  left: 64%;
}
.vraag[data-soort=begrippennet] .ballonnen[data-aantal_ballonnen="9"] .ballon[data-ballon="5"] .ballon__woord:before {
  height: 136px;
  transform: rotate(136deg);
}
.vraag[data-soort=begrippennet] .ballonnen[data-aantal_ballonnen="9"] .ballon[data-ballon="6"] {
  top: 86%;
  left: 36%;
}
.vraag[data-soort=begrippennet] .ballonnen[data-aantal_ballonnen="9"] .ballon[data-ballon="6"] .ballon__woord:before {
  height: 150px;
  transform: rotate(224deg);
}
.vraag[data-soort=begrippennet] .ballonnen[data-aantal_ballonnen="9"] .ballon[data-ballon="7"] {
  top: 67.5%;
  left: 27.5%;
}
.vraag[data-soort=begrippennet] .ballonnen[data-aantal_ballonnen="9"] .ballon[data-ballon="7"] .ballon__woord:before {
  height: 150px;
  transform: rotate(252deg);
}
.vraag[data-soort=begrippennet] .ballonnen[data-aantal_ballonnen="9"] .ballon[data-ballon="8"] {
  top: 50%;
  left: 17.5%;
}
.vraag[data-soort=begrippennet] .ballonnen[data-aantal_ballonnen="9"] .ballon[data-ballon="8"] .ballon__woord:before {
  height: 200px;
  transform: rotate(270deg);
}
.vraag[data-soort=begrippennet] .ballonnen[data-aantal_ballonnen="9"] .ballon[data-ballon="9"] {
  top: 32.5%;
  left: 27.5%;
}
.vraag[data-soort=begrippennet] .ballonnen[data-aantal_ballonnen="9"] .ballon[data-ballon="9"] .ballon__woord:before {
  height: 150px;
  transform: rotate(288deg);
}

.v-combinatie {
  margin-top: 12px;
}
.v-combinatie__item {
  margin-bottom: 12px;
}
.v-combinatie__item__left {
  width: 200px;
  border: 1px solid #bbbbbb;
  padding: 5px 10px;
  border-radius: 3px;
  margin-right: 20px;
}
.v-combinatie__item__left.focus {
  font-weight: 600;
}
.v-combinatie__item__right {
  width: 200px;
  border: 1px solid #bbbbbb;
  padding: 5px 10px;
  border-radius: 3px;
}

.vraag[data-soort=meerkeuze] .v-meerkeuze-items.show-checkbox .v-meerkeuze-item__checkbox {
  display: block;
}
.vraag[data-soort=meerkeuze] .v-meerkeuze-items.show-checkbox .v-meerkeuze-item__abc {
  display: none;
}
.vraag[data-soort=meerkeuze] .v-meerkeuze-items .v-meerkeuze-item {
  line-height: 30px;
  height: 30px;
}
.vraag[data-soort=meerkeuze] .v-meerkeuze-items .v-meerkeuze-item:nth-child(1) .v-meerkeuze-item__abc::before {
  content: "a.";
}
.vraag[data-soort=meerkeuze] .v-meerkeuze-items .v-meerkeuze-item:nth-child(2) .v-meerkeuze-item__abc::before {
  content: "b.";
}
.vraag[data-soort=meerkeuze] .v-meerkeuze-items .v-meerkeuze-item:nth-child(3) .v-meerkeuze-item__abc::before {
  content: "c.";
}
.vraag[data-soort=meerkeuze] .v-meerkeuze-items .v-meerkeuze-item:nth-child(4) .v-meerkeuze-item__abc::before {
  content: "d.";
}
.vraag[data-soort=meerkeuze] .v-meerkeuze-items .v-meerkeuze-item:nth-child(5) .v-meerkeuze-item__abc::before {
  content: "e.";
}
.vraag[data-soort=meerkeuze] .v-meerkeuze-items .v-meerkeuze-item:nth-child(6) .v-meerkeuze-item__abc::before {
  content: "f.";
}
.vraag[data-soort=meerkeuze] .v-meerkeuze-items .v-meerkeuze-item__checkbox {
  display: none;
  border: 1px solid #ced4da;
  width: 14px;
  height: 14px;
  margin: 8px 12px 3px 0;
  border-radius: 3px;
}
.vraag[data-soort=meerkeuze] .v-meerkeuze-items .v-meerkeuze-item__abc {
  position: relative;
  width: 14px;
  margin: 3px 12px 3px 0;
  font-weight: 500;
}
.vraag[data-soort=meerkeuze] .v-meerkeuze-items .v-meerkeuze-item__abc::before {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.vraag[data-soort=meerkeuze] .v-meerkeuze-items .v-meerkeuze-item__invulveld_antwoord {
  white-space: nowrap;
  margin-right: 10px;
}
.vraag[data-soort=meerkeuze] .v-meerkeuze-items .v-meerkeuze-item.v-meerkeuze-invulveld.hide {
  display: none !important;
}
.vraag[data-soort=meerkeuze] .v-meerkeuze-items .v-meerkeuze-item__input {
  width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  border: 1px solid #ced4da;
  margin-left: 10px;
}
.vraag[data-soort=meerkeuze] .v-meerkeuze-aanvullende.hide {
  display: none !important;
}
.vraag[data-soort=meerkeuze] .v-meerkeuze-input {
  line-height: 30px;
  height: 30px;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  border: 1px solid #ced4da;
  border-radius: 3px;
}

.properties[data-soort=meerkeuze] label {
  color: black;
  font-weight: bolder;
  font-size: 13px;
}
.properties[data-soort=meerkeuze] .meerkeuze-items-header {
  font-weight: 500;
  border-bottom: 1px solid #bbbbbb;
}
.properties[data-soort=meerkeuze] .meerkeuze-item {
  border-bottom: 1px solid #bbbbbb;
  padding: 6px 0;
}
.properties[data-soort=meerkeuze] .meerkeuze-item .form-check-inline {
  margin: 0 4px 0 13px;
}
.properties[data-soort=meerkeuze] .meerkeuze-item .form-check-inline .form-check-input {
  margin-right: 0;
}

.v-kruiswoordpuzzel {
  display: grid;
  grid-row-gap: 1px;
  grid-column-gap: 1px;
  background-color: #bbbbbb;
  width: fit-content;
  border: 1px solid #bbbbbb;
  margin-top: 20px;
}
.v-kruiswoordpuzzel__container {
  overflow-x: auto;
}
.v-kruiswoordpuzzel__cel {
  position: relative;
  width: 40px;
  height: 40px;
  background-color: black;
  text-align: center;
}
.v-kruiswoordpuzzel__cel:not([data-letter="@"]) {
  background-color: white;
}
.v-kruiswoordpuzzel__cel:not([data-letter="@"]):hover {
  cursor: pointer;
}
.v-kruiswoordpuzzel__cel:not([data-letter="@"]).v-kruiswoordpuzzel__cel--selected {
  background-color: #eeeeee;
}
.v-kruiswoordpuzzel__cel:not([data-letter="@"]).v-kruiswoordpuzzel__cel--current {
  background-color: #cccccc;
}
.v-kruiswoordpuzzel__cel--fout .v-kruiswoordpuzzel__cel__fout {
  border: 1px solid red;
}
.v-kruiswoordpuzzel__cel__debug {
  display: none;
  position: absolute;
  right: 0;
  bottom: 0;
  font-size: 10px;
  line-height: 10px;
  color: #bbbbbb;
}
.v-kruiswoordpuzzel__cel__nummer {
  position: absolute;
  top: 2px;
  left: 2px;
  font-size: 10px;
  line-height: 10px;
}
.v-kruiswoordpuzzel__cel__fout {
  position: absolute;
  top: 1px;
  right: 1px;
  bottom: 1px;
  left: 1px;
}
.v-kruiswoordpuzzel__cel__letter {
  color: black;
  position: relative;
  font-size: 20px;
  line-height: 40px;
  width: 40px;
  height: 40px;
  text-transform: uppercase;
}
.v-kruiswoordpuzzel__cel__letter input {
  width: 100%;
  border-width: 0px;
}

.kruiswoordpuzzel__nummer {
  line-height: 24px;
  margin-bottom: 16px;
  margin-right: 8px;
  width: 20px;
  color: black;
  text-align: right;
}

.v-woordzoeker {
  display: grid;
  grid-row-gap: 1px;
  grid-column-gap: 1px;
  background-color: #bbbbbb;
  width: fit-content;
  border: 1px solid #bbbbbb;
}
.v-woordzoeker__container {
  overflow-x: auto;
}
.v-woordzoeker__cel {
  position: relative;
  width: 40px;
  height: 40px;
  background-color: white;
  text-align: center;
}
.v-woordzoeker__cel:hover {
  cursor: pointer;
}
.v-woordzoeker__cel.v-woordzoeker__cel--selected {
  background-color: #eeeeee;
}
.v-woordzoeker__cel.v-woordzoeker__cel--current {
  background-color: #cccccc;
}
.v-woordzoeker__cel__letter {
  color: black;
  position: relative;
  font-size: 20px;
  line-height: 40px;
  width: 40px;
  height: 40px;
  text-transform: uppercase;
}
.v-woordzoeker__cel__debug {
  position: absolute;
  right: 0;
  bottom: 0;
  font-size: 10px;
  line-height: 10px;
  color: #bbbbbb;
  display: none;
}

.v-woordzoeker-woorden.toon-vraag .v-woordzoeker-woorden-vraag {
  display: block;
}
.v-woordzoeker-woorden.toon-vraag .v-woordzoeker-woorden-woord {
  color: #ffffff;
  border: 1px solid #ced4da;
}
.v-woordzoeker-woorden.toon-vraag .v-woordzoeker-woorden-woord.focus {
  border-color: black;
}
.v-woordzoeker-woorden .v-woordzoeker-woorden-vraag {
  display: none;
  line-height: 30px;
  width: 300px;
  color: black;
}
.v-woordzoeker-woorden .v-woordzoeker-woorden-vraag.focus {
  font-weight: 500;
}
.v-woordzoeker-woorden .v-woordzoeker-woorden-woord {
  color: black;
  width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  line-height: 30px;
  height: 30px;
}
.v-woordzoeker-woorden .v-woordzoeker-woorden-woord.focus {
  font-weight: 500;
}

.woordzoeker-woorden {
  border-top: 1px solid #bbbbbb;
}
.woordzoeker-woorden .woordzoeker-woord {
  border-bottom: 1px solid #bbbbbb;
}
.woordzoeker-woorden .woordzoeker-woord > div:last-child {
  display: none;
}
.woordzoeker-woorden.toon-vraag .woordzoeker-woord > div:last-child {
  display: block;
}

.v-schema {
  display: grid;
  grid-row-gap: 1px;
  grid-column-gap: 1px;
  background-color: #bbbbbb;
  border: 1px solid #bbbbbb;
  width: fit-content;
}
.v-schema__container {
  overflow-x: auto;
}
.v-schema__cel {
  position: relative;
  background-color: white;
  text-align: center;
}
.v-schema__cel:hover {
  cursor: pointer;
}
.v-schema__cel.v-schema__cel--selected {
  background-color: #eeeeee;
}
.v-schema__cel.v-schema__cel--current {
  background-color: #cccccc;
}
.v-schema__cel.titel input {
  font-weight: 500;
  background-color: #e7e7e7;
}
.v-schema__cel.antwoorden input {
  background-color: #f9f9f9;
}
.v-schema__cel.voorbeeldantwoorden input {
  background-color: #f1f1f1;
}
.v-schema__cel__content {
  color: black;
  position: relative;
  font-size: 14px;
  width: 100%;
  height: 100%;
}
.v-schema__cel__content input {
  width: 100%;
  height: 100%;
  border-width: 0px;
  padding: 0 5px;
}
.v-schema__cel__content input :focus-visible {
  border-radius: 0;
}
.v-schema__cel__debug {
  position: absolute;
  right: 0;
  bottom: 0;
  font-size: 10px;
  line-height: 10px;
  color: #bbbbbb;
  display: block;
}
.v-schema__titels {
  background-color: #e7e7e7;
  padding: 5px;
  border: 1px solid #bbbbbb;
}
.v-schema__antwoorden {
  background-color: #f9f9f9;
  padding: 5px;
  margin-left: 15px;
  border: 1px solid #bbbbbb;
}
.v-schema__voorbeeldantwoorden {
  background-color: #f1f1f1;
  padding: 5px;
  margin-left: 15px;
  border: 1px solid #bbbbbb;
}

.v-volgorde {
  margin-top: 12px;
}
.v-volgorde__item {
  margin-bottom: 12px;
}
.v-volgorde__item__left {
  min-width: 200px;
  border: 1px solid #bbbbbb;
  border-radius: 3px;
  padding: 5px 10px;
}
.v-volgorde__item__left.focus {
  font-weight: 600;
}

.v-stelling__content {
  border-radius: 0.2rem;
  margin-top: -6px;
  line-height: 30px;
  min-height: 30px;
  flex: 1;
}
.v-stelling__vraag {
  width: 100%;
  display: flex;
  margin-top: 5px;
}
.v-stelling__vraag__vraagstelling {
  margin-right: 10px;
}
.v-stelling__vraag input {
  flex: 2;
}

.v-stelling__vraag.hide {
  display: none !important;
}

.stelling {
  display: inline-block;
  border: 1px solid #ccc;
  padding: 0px 10px;
  border-radius: 4px;
  min-width: 20px;
  cursor: pointer;
}
.stelling.right {
  float: right;
}
.stelling.focus {
  border: 1px solid #009ede;
}
.stelling__veld__container {
  margin: -10px -20px;
}
.stelling__veld__items {
  border-bottom: 1px solid #eeeeee;
  padding: 12px 20px;
}
.stelling__veld__items:last-child {
  border-bottom-width: 0px;
}

.stelling-veld-item {
  margin-bottom: 8px;
}

.v-woord__veld {
  line-height: 20px;
  width: 150px;
  background-color: #ffffff;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  outline: 0;
}
.v-woord__veld.focus {
  border: 1px solid black;
}
.v-woord__veld[data-type="1"] {
  width: 100px;
}
.v-woord__veld[data-type="2"] {
  width: 175px;
}
.v-woord__veld[data-type="3"] {
  width: 250px;
}
.v-woord__veld[data-type="4"] {
  width: 100%;
  min-height: 46px;
}

.woord__veld {
  border-bottom: 1px solid #eeeeee;
  padding: 12px 20px;
}
.woord__veld:last-child {
  border-bottom-width: 0px;
}
.woord__veld .woord-veld-aantal_antwoordregels {
  display: none;
}
.woord__veld[data-type="4"] .woord-veld-aantal_antwoordregels {
  display: block;
}
.woord__veld__container {
  margin: -10px -20px;
}

.ck-editor__editable {
  min-height: 200px !important;
}

.gekoppeld-items-container {
  margin-top: 16px;
}
.gekoppeld-items-container .gekoppeld-item {
  margin-bottom: 12px;
}
.gekoppeld-items-container .gekoppeld-item .form-group {
  margin-bottom: 0;
  position: relative;
}
.gekoppeld-items-container .gekoppeld-item .form-group::after {
  font-family: "Font Awesome 6 Pro";
  position: absolute;
  top: 2px;
  right: 3px;
  width: 20px;
  height: 20px;
  text-align: center;
}
.gekoppeld-items-container .gekoppeld-item .form-group--text::after {
  content: "\f15c";
}
.gekoppeld-items-container .gekoppeld-item .form-group--afbeelding::after {
  content: "\f03e";
}
.gekoppeld-items-container .gekoppeld-item .form-group--video::after {
  content: "\f4e1";
}
.gekoppeld-items-container .gekoppeld-item .form-group--audio::after {
  content: "\f58f";
}
.gekoppeld-items-container .gekoppeld-item .form-group input {
  padding-right: 28px;
}

.gekoppeld-opdracht-items-container {
  margin-top: 16px;
}
.gekoppeld-opdracht-items-container .gekoppeld-opdracht-item {
  margin-bottom: 12px;
}
.gekoppeld-opdracht-items-container .gekoppeld-opdracht-item .form-group {
  margin-bottom: 0;
}