.form-control {
    font-size: 14px;
    line-height: 22px;
    color: #2a2a29;
    padding: 0 10px;
    height: 24px;
    position: relative;
}

textarea.form-control {
    height:100px !important;
}

.form-group {
    //max-width: 400px;

    &.has-error {
        .validator-message {
            display: block;
        }

        .form-control,
        .multiselect,
        .custom-file-label,
        .fu {
            border-color: #dc3545;
            padding-right: calc(1.5em + .75rem);
            background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
            background-repeat: no-repeat;
            background-position: right calc(.375em + .1875rem) center;
            background-size: calc(.75em + .375rem) calc(.75em + .375rem);
        }

        .fu-edit {
            display: none;
        }
    }

    > label:first-child {
        color: $black;
        font-weight: bolder;
        font-size: 13px;
    }

    .validator-message {
        display: none;
        width: 100%;
        margin-top: .25rem;
        font-size: 80%;
        color: #dc3545;
    }
}

.custom-control-input,
.custom-control-label {
    cursor: pointer !important;
}

.treejs> .treejs-nodes {
    border: 1px solid #ced4da;
    border-radius: .25rem;
    padding: 5px !important;
    margin-left: 20px !important;
    overflow-y: auto !important;
    max-height: 307px;
}

.fu {
    margin-top: 15px;
    padding: .375rem .75rem;
    overflow: hidden;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    border: 1px solid #ced4da;
    border-radius: .25rem;

    &-preview {
        width: 128px;
        height: 128px;

        img {
            max-width: 100%;
            max-height: 100%;
        }
    }
}

.bk-accordion {
    //.form-control {
    //    height: calc(1.5em + .5rem + 2px);
    //    padding: .25rem .5rem;
    //    font-size: .875rem;
    //    line-height: 1.5;
    //    border-radius: .2rem;
    //}
    //
    //.btn {
    //    display: block;
    //    padding: .25rem .5rem;
    //    font-size: .875rem;
    //    line-height: 1.5;
    //    border-radius: .2rem;
    //}
}

.ck-content .table {
    margin: 0.9em 0 !important;
    float: none!important;
}

.ck-content .table table td, .ck-content .table table th,
.ck .ck-content .table table td, .ck .ck-content .table table th {
    border: 0.1px solid #bfbfbf;
}
