@import 'variables';

.bk-accordion {
    .bk-accordion__item {
        .bk-accordion__heading {
            .bk-icon--open {
                display: flex;

            }
            .bk-icon--close {
                display: none;
            }
        }
    }

    .bk-accordion__item--open {
        .bk-accordion__heading {
            .bk-icon--open {
                display: none;

            }
            .bk-icon--close {
                display: flex;
            }
        }
    }

    &__heading {
        display: flex;
        font-size: 14px;
        line-height: 36px;
        text-transform: uppercase;
        padding: 0 20px;
        margin-top: 2px;
        background-color: #eeeeee;
        color: #2a2a29;
        cursor: pointer;
        user-select: none;

        &__title {
            flex-grow: 1;
        }

        &__icon {
            margin-top: 2px;

            .bk-icon {
                font-size: 14px;
            }
        }
    }

    &__collapse {
        padding: 10px 20px;
        user-select: none;
    }
}
