// -----------
// LAYOUT
// -----------
$bk-app__header--height: 64px;
$bk-app__left--width: 285px;
$bk-app__left--width-small: 40px;
$bk-app__main__padding-x: 40px;
$bk-app__main__left--width: 220px;
$bk-app__main__header--height: 64px;
$bk-app__main__footer--height: 64px;
$bk-app__main__right--width: 200px;
$bk-app__footer--height: 64px;
$bk-app-scrollbar-width: 0px;

$bk-panel__left--width: 222px;
$bk-panel__right--width: 450px;
$bk-panel__gutter--width: 2px;

$bk-ckeditor--height: 200px;
// -----------
// COLORS
// -----------
$black: rgba(0,0,0,1);
$black-hover: rgba(48,48,48,1);
$black-active: rgba(64,64,64,1);
$black2: #707070;
$black3: #9598a8;
$black-light: #444444;
$black-light2: #696969;
$white: rgba(255,255,255,1);
$white-hover: rgba(239,239,239,1);
$white-active: rgba(223,223,223,1);
$grey: rgba(207,207,207,1);
$grey-hover: rgba(191,191,191,1);
$grey-active: rgba(175,175,175,1);
$grey-light: #e6e6e6;
$blue: #2f54d4;
$blue-light: #0ba5f5;
$blue-light2: #00bdff;
$purple: #5500b2;
$red: rgba(200,0,101,1);
$red-hover: rgba(184,0,85,1);
$red-active: rgba(168,0,69,1);
$bk-grey: #eef0ef;

$color-set: (
  black: $black,
  black2: $black2,
  black-light: $black-light,
  black-light2: $black-light2,
  white: $white,
  grey: $grey,
  grey-light: $grey-light,
  blue: $blue,
  blue-light: $blue-light,
  blue-light2: $blue-light2,
  purple: $purple,
  red: $red
);

$clickables: (
  black: ($black, $black-hover, $black-active),
  white: ($white, $white-hover, $white-active),
  grey: ($grey, $grey-hover, $grey-active),
  red: ($red, $red-hover, $red-active),
);

// -----------
// FONTS
// -----------
$font-awesome: 'Font Awesome 6 Pro';


