@import 'variables';

* {
    /*margin: 0;
    padding: 0;*/
}
table {
    border-collapse: unset;
}
dl, ol, ul {
    padding: revert;
}

body {
    overflow: hidden;
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 14px;
    line-height: 20px;
    color: #2a2a29;

    a:hover {
        text-decoration: none;
    }
}

NOT.container,
NOT.container-fluid {
    padding-left: 0 !important;
    padding-right: 0 !important;
}

.dataTables_custom_top label {
    margin: 0!important;
}

.clickable {
    cursor: pointer;
}

.ck-rounded-corners .ck.ck-editor__top .ck-sticky-panel .ck-toolbar {
    border-radius: 0.25rem 0.25rem 0 0 !important;
}

.ck-rounded-corners .ck.ck-editor__main>.ck-editor__editable, .ck.ck-editor__main>.ck-editor__editable.ck-rounded-corners {
    border-radius: 0 0 0.25rem 0.25rem !important;
}

