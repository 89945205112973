@import 'variables';

$pos1_top: 20%;
$pos1_left: 50%;
$pos1_height: 105px;
$pos1_deg: 0;
$pos2_top: 32.5%;
$pos2_left: 72.5%;
$pos2_height: 150px;
$pos2_deg: 72;
$pos3_top: 50%;
$pos3_left: 82.5%;
$pos3_height: 200px;
$pos3_deg: 90;
$pos4_top: 67.5%;
$pos4_left: 72.5%;
$pos4_height: 150px;
$pos4_deg: 108;
$pos5_top: 80%;
$pos5_left: 50%;
$pos5_height: 105px;
$pos5_deg: 180;
$pos6_top: 67.5%;
$pos6_left: 27.5%;
$pos6_height: 150px;
$pos6_deg: 252;
$pos7_top: 50%;
$pos7_left: 17.5%;
$pos7_height: 200px;
$pos7_deg: 270;
$pos8_top: 32.5%;
$pos8_left: 27.5%;
$pos8_height: 150px;
$pos8_deg: 288;
$pos9_top: 32.5%;
$pos9_left: 27.5%;
$pos9_height: 150px;
$pos9_deg: 288;
$pos95_top: 86%;
$pos95_left: 64%;
$pos95_height: 136px;
$pos95_deg: 136;
$pos96_top: 86%;
$pos96_left: 36%;
$pos96_height: 150px;
$pos96_deg: 224;

.vraag[data-soort="begrippennet"] {
    .ballonnen {
        position: relative;
        margin-top: 12px;
        width: 100%;
        max-width: 968px;
        margin: auto;
        height: 350px;

        .ballon {
            position: absolute;
            top: 0;
            left: 0;
            z-index: 1;
            transform: translate(-50%, -50%);

            &[data-ballon="0"] {
                z-index: 2;
                top: 50%;
                left: 50%;
            }

            &__woord {
                position: relative;
                background-color: #ffffff;
                width: 200px;
                height: 50px;
                border: 1px solid #bbbbbb;
                border-radius: 50%;
                display: flex;
                justify-content: center;
                align-items: center;

                &.focus {
                    border-color: #000000;
                }

                &:before {
                    content: "";
                    z-index: -1;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    width: 1px;
                    background-color: #bbbbbb;
                    transform-origin: 0% 0%;
                }
            }
        }

        &[data-aantal_ballonnen="0"] {
            .ballon {
                &[data-ballon="1"],
                &[data-ballon="2"],
                &[data-ballon="3"],
                &[data-ballon="4"],
                &[data-ballon="5"],
                &[data-ballon="6"],
                &[data-ballon="7"],
                &[data-ballon="8"],
                &[data-ballon="9"] {
                    display: none;
                }
            }
        }

        &[data-aantal_ballonnen="1"] {
            .ballon {
                &[data-ballon="1"] {
                    top: $pos1_top;
                    left: $pos1_left;

                    .ballon__woord:before {
                        height: $pos1_height;
                        transform: rotate(#{$pos1_deg}deg);
                    }
                }

                &[data-ballon="2"],
                &[data-ballon="3"],
                &[data-ballon="4"],
                &[data-ballon="5"],
                &[data-ballon="6"],
                &[data-ballon="7"],
                &[data-ballon="8"],
                &[data-ballon="9"] {
                    display: none;
                }
            }
        }

        &[data-aantal_ballonnen="2"] {
            .ballon {
                &[data-ballon="1"] {
                    top: $pos1_top;
                    left: $pos1_left;

                    .ballon__woord:before {
                        height: $pos1_height;
                        transform: rotate(#{$pos1_deg}deg);
                    }
                }

                &[data-ballon="2"] {
                    top: $pos5_top;
                    left: $pos5_left;

                    .ballon__woord:before {
                        height: $pos5_height;
                        transform: rotate(#{$pos5_deg}deg);
                    }
                }

                &[data-ballon="3"],
                &[data-ballon="4"],
                &[data-ballon="5"],
                &[data-ballon="6"],
                &[data-ballon="7"],
                &[data-ballon="8"],
                &[data-ballon="9"] {
                    display: none;
                }
            }
        }

        &[data-aantal_ballonnen="3"] {
            .ballon {
                &[data-ballon="1"] {
                    top: $pos1_top;
                    left: $pos1_left;

                    .ballon__woord:before {
                        height: $pos1_height;
                        transform: rotate(#{$pos1_deg}deg);
                    }
                }

                &[data-ballon="2"] {
                    top: $pos4_top;
                    left: $pos4_left;

                    .ballon__woord:before {
                        height: $pos4_height;
                        transform: rotate(#{$pos4_deg}deg);
                    }
                }

                &[data-ballon="3"] {
                    top: $pos6_top;
                    left: $pos6_left;

                    .ballon__woord:before {
                        height: $pos6_height;
                        transform: rotate(#{$pos6_deg}deg);
                    }
                }

                &[data-ballon="4"],
                &[data-ballon="5"],
                &[data-ballon="6"],
                &[data-ballon="7"],
                &[data-ballon="8"],
                &[data-ballon="9"] {
                    display: none;
                }
            }
        }

        &[data-aantal_ballonnen="4"] {
            .ballon {
                &[data-ballon="1"] {
                    top: $pos2_top;
                    left: $pos2_left;

                    .ballon__woord:before {
                        height: $pos2_height;
                        transform: rotate(#{$pos2_deg}deg);
                    }
                }

                &[data-ballon="2"] {
                    top: $pos4_top;
                    left: $pos4_left;

                    .ballon__woord:before {
                        height: $pos4_height;
                        transform: rotate(#{$pos4_deg}deg);
                    }
                }

                &[data-ballon="3"] {
                    top: $pos6_top;
                    left: $pos6_left;

                    .ballon__woord:before {
                        height: $pos6_height;
                        transform: rotate(#{$pos6_deg}deg);
                    }
                }

                &[data-ballon="4"] {
                    top: $pos8_top;
                    left: $pos8_left;

                    .ballon__woord:before {
                        height: $pos8_height;
                        transform: rotate(#{$pos8_deg}deg);
                    }
                }

                &[data-ballon="5"],
                &[data-ballon="6"],
                &[data-ballon="7"],
                &[data-ballon="8"],
                &[data-ballon="9"] {
                    display: none;
                }
            }
        }

        &[data-aantal_ballonnen="5"] {
            .ballon {
                &[data-ballon="1"] {
                    top: $pos1_top;
                    left: $pos1_left;

                    .ballon__woord:before {
                        height: $pos1_height;
                        transform: rotate(#{$pos1_deg}deg);
                    }
                }

                &[data-ballon="2"] {
                    top: $pos2_top;
                    left: $pos2_left;

                    .ballon__woord:before {
                        height: $pos2_height;
                        transform: rotate(#{$pos2_deg}deg);
                    }
                }

                &[data-ballon="3"] {
                    top: $pos4_top;
                    left: $pos4_left;

                    .ballon__woord:before {
                        height: $pos4_height;
                        transform: rotate(#{$pos4_deg}deg);
                    }
                }

                &[data-ballon="4"] {
                    top: $pos6_top;
                    left: $pos6_left;

                    .ballon__woord:before {
                        height: $pos6_height;
                        transform: rotate(#{$pos6_deg}deg);
                    }
                }

                &[data-ballon="5"] {
                    top: $pos8_top;
                    left: $pos8_left;

                    .ballon__woord:before {
                        height: $pos8_height;
                        transform: rotate(#{$pos8_deg}deg);
                    }
                }

                &[data-ballon="6"],
                &[data-ballon="7"],
                &[data-ballon="8"],
                &[data-ballon="9"] {
                    display: none;
                }
            }
        }

        &[data-aantal_ballonnen="6"] {
            .ballon {
                &[data-ballon="1"] {
                    top: $pos2_top;
                    left: $pos2_left;

                    .ballon__woord:before {
                        height: $pos2_height;
                        transform: rotate(#{$pos2_deg}deg);
                    }
                }

                &[data-ballon="2"] {
                    top: $pos3_top;
                    left: $pos3_left;

                    .ballon__woord:before {
                        height: $pos3_height;
                        transform: rotate(#{$pos3_deg}deg);
                    }
                }

                &[data-ballon="3"] {
                    top: $pos4_top;
                    left: $pos4_left;

                    .ballon__woord:before {
                        height: $pos4_height;
                        transform: rotate(#{$pos4_deg}deg);
                    }
                }

                &[data-ballon="4"] {
                    top: $pos6_top;
                    left: $pos6_left;

                    .ballon__woord:before {
                        height: $pos6_height;
                        transform: rotate(#{$pos6_deg}deg);
                    }
                }

                &[data-ballon="5"] {
                    top: $pos7_top;
                    left: $pos7_left;

                    .ballon__woord:before {
                        height: $pos7_height;
                        transform: rotate(#{$pos7_deg}deg);
                    }
                }

                &[data-ballon="6"] {
                    top: $pos8_top;
                    left: $pos8_left;

                    .ballon__woord:before {
                        height: $pos8_height;
                        transform: rotate(#{$pos8_deg}deg);
                    }
                }

                &[data-ballon="7"],
                &[data-ballon="8"],
                &[data-ballon="9"] {
                    display: none;
                }
            }
        }

        &[data-aantal_ballonnen="7"] {
            .ballon {
                &[data-ballon="1"] {
                    top: $pos1_top;
                    left: $pos1_left;

                    .ballon__woord:before {
                        height: $pos1_height;
                        transform: rotate(#{$pos1_deg}deg);
                    }
                }

                &[data-ballon="2"] {
                    top: $pos2_top;
                    left: $pos2_left;

                    .ballon__woord:before {
                        height: $pos2_height;
                        transform: rotate(#{$pos2_deg}deg);
                    }
                }

                &[data-ballon="3"] {
                    top: $pos3_top;
                    left: $pos3_left;

                    .ballon__woord:before {
                        height: $pos3_height;
                        transform: rotate(#{$pos3_deg}deg);
                    }
                }

                &[data-ballon="4"] {
                    top: $pos4_top;
                    left: $pos4_left;

                    .ballon__woord:before {
                        height: $pos4_height;
                        transform: rotate(#{$pos4_deg}deg);
                    }
                }

                &[data-ballon="5"] {
                    top: $pos6_top;
                    left: $pos6_left;

                    .ballon__woord:before {
                        height: $pos6_height;
                        transform: rotate(#{$pos6_deg}deg);
                    }
                }

                &[data-ballon="6"] {
                    top: $pos7_top;
                    left: $pos7_left;

                    .ballon__woord:before {
                        height: $pos7_height;
                        transform: rotate(#{$pos7_deg}deg);
                    }
                }

                &[data-ballon="7"] {
                    top: $pos8_top;
                    left: $pos8_left;

                    .ballon__woord:before {
                        height: $pos8_height;
                        transform: rotate(#{$pos8_deg}deg);
                    }
                }

                &[data-ballon="8"],
                &[data-ballon="9"] {
                    display: none;
                }
            }
        }

        &[data-aantal_ballonnen="8"] {
            .ballon {
                &[data-ballon="1"] {
                    top: $pos1_top;
                    left: $pos1_left;

                    .ballon__woord:before {
                        height: $pos1_height;
                        transform: rotate(#{$pos1_deg}deg);
                    }
                }

                &[data-ballon="2"] {
                    top: $pos2_top;
                    left: $pos2_left;

                    .ballon__woord:before {
                        height: $pos2_height;
                        transform: rotate(#{$pos2_deg}deg);
                    }
                }

                &[data-ballon="3"] {
                    top: $pos3_top;
                    left: $pos3_left;

                    .ballon__woord:before {
                        height: $pos3_height;
                        transform: rotate(#{$pos3_deg}deg);
                    }
                }

                &[data-ballon="4"] {
                    top: $pos4_top;
                    left: $pos4_left;

                    .ballon__woord:before {
                        height: $pos4_height;
                        transform: rotate(#{$pos4_deg}deg);
                    }
                }

                &[data-ballon="5"] {
                    top: $pos5_top;
                    left: $pos5_left;

                    .ballon__woord:before {
                        height: $pos5_height;
                        transform: rotate(#{$pos5_deg}deg);
                    }
                }

                &[data-ballon="6"] {
                    top: $pos6_top;
                    left: $pos6_left;

                    .ballon__woord:before {
                        height: $pos6_height;
                        transform: rotate(#{$pos6_deg}deg);
                    }
                }

                &[data-ballon="7"] {
                    top: $pos7_top;
                    left: $pos7_left;

                    .ballon__woord:before {
                        height: $pos7_height;
                        transform: rotate(#{$pos7_deg}deg);
                    }
                }

                &[data-ballon="8"] {
                    top: $pos8_top;
                    left: $pos8_left;

                    .ballon__woord:before {
                        height: $pos8_height;
                        transform: rotate(#{$pos8_deg}deg);
                    }
                }

                &[data-ballon="9"] {
                    display: none;
                }
            }
        }

        &[data-aantal_ballonnen="9"] {
            .ballon {
                &[data-ballon="1"] {
                    top: $pos1_top;
                    left: $pos1_left;

                    .ballon__woord:before {
                        height: $pos1_height;
                        transform: rotate(#{$pos1_deg}deg);
                    }
                }

                &[data-ballon="2"] {
                    top: $pos2_top;
                    left: $pos2_left;

                    .ballon__woord:before {
                        height: $pos2_height;
                        transform: rotate(#{$pos2_deg}deg);
                    }
                }

                &[data-ballon="3"] {
                    top: $pos3_top;
                    left: $pos3_left;

                    .ballon__woord:before {
                        height: $pos3_height;
                        transform: rotate(#{$pos3_deg}deg);
                    }
                }

                &[data-ballon="4"] {
                    top: $pos4_top;
                    left: $pos4_left;

                    .ballon__woord:before {
                        height: $pos4_height;
                        transform: rotate(#{$pos4_deg}deg);
                    }
                }

                &[data-ballon="5"] {
                    top: $pos95_top;
                    left: $pos95_left;

                    .ballon__woord:before {
                        height: $pos95_height;
                        transform: rotate(#{$pos95_deg}deg);
                    }
                }

                &[data-ballon="6"] {
                    top: $pos96_top;
                    left: $pos96_left;

                    .ballon__woord:before {
                        height: $pos96_height;
                        transform: rotate(#{$pos96_deg}deg);
                    }
                }

                &[data-ballon="7"] {
                    top: $pos6_top;
                    left: $pos6_left;

                    .ballon__woord:before {
                        height: $pos6_height;
                        transform: rotate(#{$pos6_deg}deg);
                    }
                }

                &[data-ballon="8"] {
                    top: $pos7_top;
                    left: $pos7_left;

                    .ballon__woord:before {
                        height: $pos7_height;
                        transform: rotate(#{$pos7_deg}deg);
                    }
                }

                &[data-ballon="9"] {
                    top: $pos9_top;
                    left: $pos9_left;

                    .ballon__woord:before {
                        height: $pos9_height;
                        transform: rotate(#{$pos9_deg}deg);
                    }
                }
            }
        }

    }
}
