@import 'variables';
.bk-breadcrumb {
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    padding: 0;
    margin: 0;
    list-style: none;
    font-size: 14px;
    font-weight: 600;
    color: #555554;

    a {
        color: #555554;
    }
}

.bk-breadcrumb-item+.bk-breadcrumb-item {
    padding-left: .5rem;
}

.bk-breadcrumb-item+.bk-breadcrumb-item::before {
    float: left;
    padding-right: .5rem;
    color: #555554;
    content: ">";
}

.bk-breadcrumb-item.active {
    color: #555554;
}

.bk-breadcrumb-item+.bk-breadcrumb-item::before {
    float: left;
    padding-right: .5rem;
    color: #555554;
    content: ">";
}
